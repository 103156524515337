import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import useAuth from './useAuth';
import useRefreshToken from './useRefreshToken';
import { axiosPrivate } from '../api/axios';

const useAxiosPrivate = () => {
  const authData = useAuth();
  const { auth } = authData || {}; // Handle null authData
  const refresh = useRefreshToken();
  const navigate = useNavigate();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config: any) => {
        if (!config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${auth.accessToken}`;
        }
        return config;
      },
      (error: any) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response: any) => response,
      async (error: any) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh, navigate]);

  return axiosPrivate;
};

export default useAxiosPrivate;
