/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';

import { CloseCircleOutlined, InboxOutlined } from '@ant-design/icons';
import {
  UploadProps,
  message,
  Select,
  Table,
  Space,
  Button,
  Row,
  Col,
  Card,
  Tag,
  Tooltip,
  Input,
  Steps,
  notification,
  Divider,
  Alert,
  Empty,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import Dragger from 'antd/lib/upload/Dragger';
import { useQueryClient, useQuery, useMutation } from 'react-query';

import CSVService from '../../../APIServices/CSVService';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { LogType } from '../../../models/LogType';
import {
  convertDate,
  convertMillisecondsToDHMS,
} from '../../../utils/ConvertDate';

interface Props {
  sliam?: boolean;
  tab: string;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const SliamUploader: React.FC<Props> = ({ sliam, tab }) => {
  const { auth } = useAuth();
  const [uploadFileType, setUploadFileType] = useState('customer-site-import');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [filters, setFilters] = useState<any>('');
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  const [exeUploadButtonLoading, setExeUploadButtonLoading] = useState(false);

  const [disableExeUploadButton, setDisableExeUploadButton] = useState(false);
  const [disableUploadSourceFileButton, setDisableUploadSourceFileButton] =
    useState(false);

  const [searchField, setSearchField] = useState('Select Field');
  const [searchFieldSub, setSearchFieldSub] = useState('Select Field');
  const [api, contextHolder] = notification.useNotification();
  const [isSubField, setIsSubField] = useState(false);
  const [selectSearchTerms, setSelectSearchTerms] = useState<string>('');
  const [disableSearch, setDisableSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentStep, setCurrentStep] = useState<any>(0);
  const [enableSyncButton, setEnableSyncButton] = useState(true);
  const [requestId, setRequestId] = useState<any>('');
  const [uploadFileTypeTitle, setUploadFileTypeTitle] =
    useState('Customer Site Data');

  const [inProgress, setInProgress] = useState(false);
  const { Search } = Input;
  const { Option } = Select;

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  const downloadSummaryFile = (log: LogType, fileName: string) => {
    axiosPrivate({
      method: 'GET',
      url: `/impex/import/logs/${log.id}/summaryfile`,
      responseType: 'blob',
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        openNotificationWithIcon('success', 'Success', 'Download successful.');
      })
      .catch((error) => {
        openNotificationWithIcon(
          'error',
          'Error',
          'Download failed, some files may be archived and not available for download anymore.'
        );
        console.log(error, 'error');
      });
  };

  const searchFields = [
    { id: 'progressStatus', value: 'Progress Status' },
    { id: 'fileType', value: 'File Type' },
    // { id: 'srcFile', value: 'File' },
  ];

  const [statusDropdowns, setStatusDropdowns] = useState([
    { id: 'FilePersisted', value: 'Accepted' },
    { id: 'NonStarted', value: 'Non Started' },
    { id: 'InProgress', value: 'In Progress' },
    { id: 'InProcessQueue', value: 'In Queue' },
    { id: 'Completed', value: 'Completed' },
    { id: 'Aborted', value: 'Aborted' },
    { id: 'Paused', value: 'Paused' },
  ]);

  const [typeDropdowns, setTypeDropdowns] = useState([
    { id: 'customer_site', value: 'Customer Site' },
    { id: 'asset_ib_extract', value: 'Asset IB Extract' },
    { id: 'service_contract', value: 'Service Contract' },
    { id: 'asset_geotag_scan', value: 'Asset GeoTag Scan' },
    { id: 'AssetClassServiceMap', value: 'Asset Class Service Map' },
    { id: 'auto_rule_job', value: 'Auto Rule Job' },
  ]);

  const {
    data: recentUploads,
    isLoading,
    refetch: refetchRecentUploads,
    error,
  } = useQuery(['recentUploads', page, pageSize, filters], () =>
    CSVService.getUploadLogs(page, pageSize, filters)
  );

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    accept: '.csv, .xlsx',
    showUploadList: false,
    // action: `${process.env.REACT_APP_API_URL}impex/import/${uploadFileType}`,
    customRequest: (options: any) => {
      const formData = new FormData();
      formData.append('file', options.file);
      setInProgress(true);
      axiosPrivate({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}impex/import/${uploadFileType}`,
        data: formData,
      })
        .then((res) => {
          options.onSuccess(res, options.file);
          setRequestId(res.data.logId);
          queryClient.invalidateQueries('uploads');
          setInProgress(false);
        })
        .catch((err) => {
          options.onError(err, options.file);
        });
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList, 'uploading');
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        refetchRecentUploads();
        setInProgress(false);
      } else if (status === 'error') {
        refetchRecentUploads();
        setInProgress(false);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  useEffect(() => {
    switch (currentStep) {
      case 0:
        setUploadFileTypeTitle('Customer Site Data');
        setUploadFileType('customer-site-import');
        break;
      case 1:
        setUploadFileTypeTitle('Asset IB Extract');
        setUploadFileType('asset-install-base');
        break;
      case 2:
        setUploadFileTypeTitle('Asset GeoTag Scan');
        setUploadFileType('asset-geotag-scan');
        break;
      case 3:
        setUploadFileTypeTitle('CFS/MCN Data Extract');
        setUploadFileType('mcn-data-extract');
        break;
      case 4:
        setEnableSyncButton(false);
        break;
      default:
        setUploadFileTypeTitle('Customer Site Data');
        setUploadFileType('customer-site-import');
        break;
    }
  }, [currentStep]);

  const convertDateToNumber = (d: string) => {
    const p = d.split('/');
    return +(p[2] + p[1] + p[0]);
  };

  const columnsUploads: ColumnsType<LogType> = [
    {
      title: 'Status',
      dataIndex: 'progressStatus',
      key: 'progressStatus',
      render: (_, record) => {
        let color = 'green';
        let text;
        if (record.progressStatus === 'FilePersisted') {
          color = 'slateBlue';
          text = 'Accepted';
        }
        if (record.progressStatus === 'NonStarted') {
          color = 'darkGray';
          text = 'Not Started';
        }
        if (record.progressStatus === 'InProgress') {
          color = 'gold';
          text = 'In Progress';
        }
        if (record.progressStatus === 'InProcessQueue') {
          color = 'blue';
          text = 'In Queue';
        }
        if (record.progressStatus === 'Completed') {
          color = 'green';
          text = 'Completed';
        }
        if (record.progressStatus === 'Aborted') {
          color = 'red';
          text = 'Aborted';
        }
        if (record.progressStatus === 'Failed') {
          return (
            <>
              {' '}
              <Tooltip title={record?.remark}>
                <Tag color="red" icon={<CloseCircleOutlined />}>
                  Failed
                </Tag>
                {record.failedCount > 0 && (
                  <Tag color="red">Failed with Errors</Tag>
                )}
              </Tooltip>
            </>
          );
        }
        if (record.progressStatus === 'Paused') {
          color = 'orange';
          text = 'Paused';
        }
        return (
          <>
            {' '}
            <Tag color={color}>{text}</Tag>
            {record.failedCount > 0 && (
              <Tag color="red">{text} with Errors</Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'File Name',
      dataIndex: 'srcFile',
      key: 'srcFile',
    },

    {
      title: 'Success Count',
      dataIndex: 'successCount',
      key: 'successCount',
      render: (record) => {
        return <Tag color="green">{record}</Tag>;
      },
    },
    {
      title: 'Failed Count',
      dataIndex: 'failedCount',
      key: 'failedCount',
      render: (record) => {
        return (
          <Tooltip title="To view failed records download execution summary">
            <Tag color="red">{record}</Tag>
          </Tooltip>
        );
      },
    },
    {
      title: 'Execution Time',
      dataIndex: 'executionTime',
      key: 'executionTime',
      render: (time) => {
        return (
          <Tag color="default">
            {time ? convertMillisecondsToDHMS(time) : 'Not Available'}
          </Tag>
        );
      },
    },
    {
      title: 'Uploaded By',
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
    },
    {
      title: 'File Type',
      dataIndex: 'fileType',
      key: 'fileType',
      render: (_, { fileType }) => {
        if (fileType === 'service_contract') {
          return 'Service Contract';
        }
        if (fileType === 'asset_ib_extract') {
          return 'Asset IB Extract';
        }
        if (fileType === 'asset_geotag_scan') {
          return 'Asset GeoTag Scan';
        }
        if (fileType === 'customer_site') {
          return 'Customer Site';
        }
        if (fileType === 'AssetClassServiceMap') {
          return 'Asset Class Service Map';
        }
        if (fileType === 'auto_rule_job') {
          return 'Auto Rule Job';
        }
        return fileType;
      },
    },
    {
      title: 'Uploaded',
      dataIndex: 'uploadedAt',
      key: 'uploadedAt',
      responsive: ['xxl'],
      sortDirections: ['descend', 'ascend'],
      render: (time) => {
        return convertDate(time);
      },
      sorter: (a, b) =>
        convertDateToNumber(a.uploadedAt!) - convertDateToNumber(b.uploadedAt!),
    },
    {
      title: 'Execution Summary',
      key: 'action',
      render: (_, record) => {
        if (record.progressStatus === 'Completed' && record.summaryFile) {
          return (
            <Space size="middle">
              <Button
                size="small"
                type="primary"
                loading={exeUploadButtonLoading}
                disabled={disableExeUploadButton}
                onClick={() => downloadSummaryFile(record!, record.summaryFile)}
              >
                Download
              </Button>
            </Space>
          );
        }
        return <>Not Currently Available</>;
      },
    },
  ];

  const handleChange = (pagination: any) => {
    setPage(pagination.current - 1);
    setPageSize(pagination.pageSize);
  };

  const setSearchTerms = (value: string) => {
    setSearchFieldSub(value);
    if (searchField === 'progressStatus' || searchField === 'fileType') {
      setFilters(`${searchField}=${value}`);
    } else {
      setFilters(``);
    }
  };

  const setMainSearchTerms = (value: string) => {
    if (searchField === 'progressStatus' || searchField === 'fileType') {
      setFilters(value);
    }
    setSearchField(value);
    setSelectSearchTerms(value);
    setIsSubField(false);
    setSearchFieldSub('');
  };

  useEffect(() => {
    if (searchField === 'progressStatus' || searchField === 'fileType') {
      setIsSubField(true);
      setSearchFieldSub('Select Field');
      setDisableSearch(true);
      setSearchValue('');
    } else {
      setIsSubField(false);
      setSearchFieldSub('Select Field');
      setSelectSearchTerms('');
      setDisableSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const selectBefore = (
    <div>
      <Select
        defaultValue="Select Field"
        placeholder="Select Field"
        value={searchField}
        onChange={(e) => setMainSearchTerms(e)}
        style={{ width: 200 }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {searchFields?.map((item) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.value}
            </Option>
          );
        })}
      </Select>
      {searchField === 'progressStatus' && (
        <>
          <Select
            defaultValue="Select Field"
            placeholder="Select Field"
            value={searchFieldSub}
            onChange={(e) => setSearchTerms(e)}
            style={{ width: 200 }}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            {statusDropdowns?.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </>
      )}
      {searchField === 'fileType' && (
        <>
          <Select
            defaultValue="Select Field"
            placeholder="Select Field"
            value={searchFieldSub}
            onChange={(e) => setSearchTerms(e)}
            style={{ width: 200 }}
          >
            {typeDropdowns?.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </>
      )}
      {searchField === 'progressStatus' || searchField === 'fileType' ? (
        <Button
          type="primary"
          size="small"
          className="clear-button"
          onClick={() => {
            setSearchValue('');
            setFilters('');
            setSearchField('Select Field');
            setSearchFieldSub('Select Field');
            setDisableSearch(false);
            setPage(0);
            setPageSize(5);
          }}
        >
          Clear Filter
          <CloseCircleOutlined />
        </Button>
      ) : null}
    </div>
  );

  const handleSearch = (e: any) => {
    const encodedValue = encodeURIComponent(e.trim());
    setPage(0);
    setPageSize(10);
    setFilters(`${searchField}=${encodedValue}`);
    queryClient.invalidateQueries('recentUploads');
  };

  const processUploads = useMutation({
    mutationFn: () => CSVService.processUploads(),
    onSuccess: (res) => {
      switch (res.data) {
        case 'NoBatchJobToProcess':
          openNotificationWithIcon(
            'warning',
            'No files to process',
            'Please upload files to begin processing.'
          );
          break;
        case 'BatchJobInProcess':
          openNotificationWithIcon(
            'info',
            'Sync in progress',
            'Please wait for the current sync to finish.'
          );
          break;
        case 'BatchJobInPipeline':
          openNotificationWithIcon(
            'info',
            'Batch added to pipeline',
            'Another Batch in Process and this batch is pushed in the waiting pipeline.'
          );
          break;
        default:
          openNotificationWithIcon(
            'success',
            'Success',
            'Sync in progress, please check the uploads table for updates.'
          );
          break;
      }

      queryClient.clear();
    },
    onError: () => {
      openNotificationWithIcon(
        'error',
        'Failed',
        'Sync failed, please try again or contact support.'
      );
    },
  });

  const onChange = (value: number) => {
    setCurrentStep(value);
  };
  return (
    <>
      {contextHolder}
      <Row gutter={20}>
        <Col md={24} lg={24} xl={7}>
          <Card
            title="Uploader"
            style={{ marginBottom: 32 }}
            extra={
              <Tooltip title="Click to perform data sync and see updates in the uploads table.">
                <Button
                  type="primary"
                  loading={inProgress}
                  disabled={inProgress}
                  onClick={() => {
                    processUploads.mutate();
                  }}
                >
                  Sync Data
                </Button>
              </Tooltip>
            }
          >
            <>
              <div className="alert-download">
                <Alert
                  message="Files will be processed in the background once 'Sync Data' button has been clicked. You can view the status updates in the uploads table."
                  type="info"
                  showIcon
                />
                <br />
              </div>

              <Steps
                current={currentStep}
                size="small"
                direction="vertical"
                onChange={onChange}
                items={[
                  {
                    title: 'Customer Site Data',
                    disabled: inProgress,
                  },
                  {
                    title: 'Base Asset',
                    disabled: inProgress,
                  },
                  {
                    title: 'Geo Tag Data',
                    disabled: inProgress,
                  },
                  {
                    title: 'CFS/MCN Data Extract',
                    disabled: inProgress,
                  },
                ]}
              />
              <div
                className={`flex ${
                  currentStep === 0 ? 'justify-end' : 'justify-between'
                } `}
              >
                {currentStep > 0 && (
                  <Button
                    type="default"
                    loading={inProgress}
                    style={{ margin: '0 8px' }}
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                )}
                {currentStep >= 0 && currentStep < 3 ? (
                  <Button
                    type="primary"
                    loading={inProgress}
                    onClick={() => next()}
                  >
                    Next
                  </Button>
                ) : null}
              </div>
            </>
            <Divider />
            <br />
            <>
              <h3
                style={{
                  textAlign: 'center',
                  marginTop: '1rem',
                  textTransform: 'capitalize',
                }}
              >
                Upload {uploadFileTypeTitle}
              </h3>
              <Dragger {...props} height={200} disabled={inProgress}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload{' '}
                  {uploadFileTypeTitle}
                </p>
                <p className="ant-upload-hint">Support for a single upload.</p>
              </Dragger>
            </>
          </Card>
        </Col>
        <Col md={24} lg={24} xl={17}>
          <Card
            title="Uploads"
            style={{ marginBottom: 22 }}
            extra={
              <Button type="primary" onClick={() => refetchRecentUploads()}>
                Refresh
              </Button>
            }
          >
            <div className="search-bar">
              <Search
                addonBefore={selectBefore}
                placeholder="Search"
                enterButton="Search"
                size="large"
                allowClear
                className={`${disableSearch ? 'disabled' : ''}`}
                disabled={disableSearch}
                onSearch={(e) => handleSearch(e)}
              />
            </div>
            <br />
            <Table
              columns={columnsUploads}
              id="recent-uploads"
              rowKey={(record) => record.id!}
              dataSource={recentUploads?.data}
              loading={isLoading}
              locale={{
                emptyText: <Empty description="No uploads" />,
              }}
              onChange={handleChange}
              pagination={{
                defaultPageSize: 5,
                current: page + 1,
                pageSize,
                total: Number(recentUploads?.headers['x-total-count']),
                pageSizeOptions: ['5', '10', '20', '50', '100'],
                showSizeChanger: true,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SliamUploader;
