import { useState, useEffect } from 'react';

interface TagsData {
  tag: string;
  text: string;
  color: string;
}

export const useTagsWithColors = (obj: {
  [key: string]: boolean;
}): TagsData[] => {
  const [tagsList, setTagsList] = useState<TagsData[]>([]);

  function addTag(tag: string, text: string, color: string) {
    setTagsList((prevTagsList) => [...prevTagsList, { tag, text, color }]);
  }

  useEffect(() => {
    setTagsList([]);
    if (obj && typeof obj === 'object') {
      if (obj.autoUpdated === true) {
        addTag('autoUpdated', 'Auto Updated', 'blue');
      }

      if (obj.autoMoved === true) {
        addTag('autoMoved', 'Auto Moved', 'blue');
      }

      if (obj.contractUpdate === true) {
        addTag('contractUpdate', 'Contract Update', 'blue');
      }
      if (obj.locationUpdated === true) {
        addTag('locationUpdated', 'Location Updated', 'blue');
      }
      if (obj.serviceUpdated === true) {
        addTag('serviceUpdated', 'Service Updated', 'blue');
      }
      if (obj.updateFrozen === true) {
        addTag('updateFrozen', 'Frozen Update', 'blue');
      }
      if (obj.actionRequired === true) {
        addTag('actionRequired', 'Action Required', 'red');
      }
      if (obj.newLocationScan === true) {
        addTag('newLocationScan', 'New Location Scan', 'blue');
      }
    }
  }, [obj]);

  return tagsList;
};
