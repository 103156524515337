import { useNavigate } from 'react-router-dom';

import useAuth from './useAuth';

const useLogout = () => {
  const authData = useAuth();
  const { setAuth } = authData || {}; // Handle null authData
  const navigate = useNavigate();

  const logout = async () => {
    setAuth({});
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('customer');
    sessionStorage.removeItem('user');
    navigate('/');
  };

  return logout;
};

export default useLogout;
