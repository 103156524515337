import React, { useState } from 'react';

import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Menu, Breadcrumb, Row, Col, MenuProps } from 'antd';
import './NCRClientDashboard.scss';
import { Link, useLocation, Outlet } from 'react-router-dom';

import { UserRole } from '../../../auth/AuthProvider';
import PersistLogin from '../../../auth/PersistLogin';
import RequireAuth from '../../../auth/RequireAuth';
import { useClient } from '../../../Context/ClientContextProvider';
import * as ROUTES from '../../../Routes/Routes';

const NCRClientDashboard: React.FC = () => {
  const [current, setCurrent] = useState('mail');
  const customerID: string = window.location.pathname.split('/').slice(-2)[0];
  const location = useLocation();
  const { singleClient } = useClient();

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link
          className={
            location.pathname ===
            `${`${ROUTES.DASHBOARD_NCR}/customers/${customerID}/${ROUTES.CLIENT_OVERVIEW}`}`
              ? 'active'
              : ''
          }
          to={ROUTES.CLIENT_OVERVIEW}
        >
          Client Home
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link
          className={
            location.pathname ===
            `${ROUTES.DASHBOARD_NCR}/customers/${customerID}/${ROUTES.CLIENT_PROFILE_ASSETS}`
              ? 'active'
              : ''
          }
          to={ROUTES.CLIENT_PROFILE_ASSETS}
        >
          Client Assets
        </Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link
          className={
            location.pathname ===
            `${ROUTES.DASHBOARD_NCR}/customers/${customerID}/${ROUTES.CLIENT_PROFILE_CONTRACTS}`
              ? 'active'
              : ''
          }
          to={ROUTES.CLIENT_PROFILE_CONTRACTS}
        >
          Client Contracts
        </Link>
      ),
    },
    {
      key: '4',
      label: (
        <Link
          className={
            location.pathname ===
            `${ROUTES.DASHBOARD_NCR}/customers/${customerID}/${ROUTES.CLIENT_SITES}`
              ? 'active'
              : ''
          }
          to={ROUTES.CLIENT_SITES}
        >
          Client Sites
        </Link>
      ),
    },
  ];

  return (
    <PersistLogin>
      <RequireAuth allowedRoles={[UserRole.client, UserRole.admin]}>
        <div className="client-dashboard">
          <Row>
            <Col span={24} className="client-header" id="clientNav">
              <span>
                <h1 className="page-title">{singleClient?.customerName}</h1>
                <h2>{singleClient?.customerNbr}</h2>
              </span>
            </Col>
            <Col span={24}>
              <Menu
                mode="horizontal"
                theme="light"
                className="main-nav"
                items={menuItems}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} className="dashboard-outlet">
              <br />
              <Outlet />
            </Col>
          </Row>
        </div>
      </RequireAuth>
    </PersistLogin>
  );
};

export default NCRClientDashboard;
