import React, { useState } from 'react';

import { ClockCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Empty,
  List,
  Modal,
  Result,
  Row,
  Spin,
  Table,
  Tag,
  Tooltip,
  notification,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import ContractService from '../../APIServices/ContractService';
import { ServiceType } from '../../models/ServiceType';
import { convertDate } from '../../utils/ConvertDate';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface ContractEntitlementsProps {
  contractServices: any;
}
const { Panel } = Collapse;

const ContractEntitlements: React.FC<ContractEntitlementsProps> = ({
  contractServices,
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState('serviceUpdated=true');
  const [revertModal, setRevertModal] = useState(false);
  const [revertModalData, setRevertModalData] = useState(false);
  const [snapshotId, setSnapshotId] = useState('');
  const [dateId, setDateId] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();
  const contractID: string = window.location.pathname.split('/').pop()!;
  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  const columns: ColumnsType<ServiceType> = [
    {
      title: 'Entitlements Number',
      dataIndex: 'serviceNbr',
      key: 'serviceNbr',
    },
    {
      title: 'Contracts Identifier',
      dataIndex: 'contractIdentifier',
      key: 'contractIdentifier',
    },
    {
      title: 'Category',
      dataIndex: 'serviceCategory',
      key: 'serviceCategory',
    },
    {
      title: 'Status',
      dataIndex: 'autoUpdate',
      key: 'autoUpdate',
      render: (_, record) => {
        if (record.added || record.autoUpdate || record.active) {
          return <Tag color="green">Active</Tag>;
        }
        return <Tag color="default">-</Tag>;
      },
    },
    {
      title: 'Auto Update',
      dataIndex: 'autoUpdate',
      key: 'autoUpdate',
      render: (_, record) => {
        if (record.autoUpdate && record.added === true) {
          return <Tag color="blue">Auto Added</Tag>;
        }
        if (!record.autoUpdate && record.added === true) {
          return <Tag color="purple">Manually Added</Tag>;
        }

        return <Tag color="default">-</Tag>;
      },
    },
  ];

  const { data: entitlementsHistory, isLoading: isLoadingEntitlements } =
    useQuery(['currentEntitlements', contractID, page, pageSize, filter], () =>
      ContractService.getContractHistory(contractID, page, pageSize, filter)
    );

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const handleRevert = (id: string, time: string) => {
    setSnapshotId(id);
    setDateId(time);
    setRevertModal(true);
  };

  const setRevertSnapshot = useMutation({
    mutationFn: () => ContractService.restoreSnapshot(snapshotId),
    onSuccess: () => {
      openNotificationWithIcon(
        'success',
        'Success',
        'Contract revert successful'
      );
      setRevertModal(false);
      queryClient.invalidateQueries(['contracts', 'singleContract']);
    },
    onError: () => {
      openNotificationWithIcon('error', 'Failed', 'Contract revert failed');
    },
  });

  const filterRemovedServices = (services: [ServiceType]) => {
    const activeItems = services?.filter(
      (item: ServiceType) =>
        (item.added || item.autoUpdate || item.active) && !item.removed
    );
    return activeItems;
  };

  return (
    <Row>
      <Col span={24}>
        <Card title="Current Entitlements" className=" snaps">
          <Table
            columns={columns}
            rowKey={(record) => record.id!}
            dataSource={filterRemovedServices(contractServices)}
            loading={isLoadingEntitlements}
            pagination={false}
            locale={{
              emptyText: (
                <Empty description="No entitlements associated with this contract" />
              ),
            }}
          />
          <br />
        </Card>
        <br />
        <Card title="Entitlements History" className="snaps">
          {entitlementsHistory?.data.length >= 1 ? (
            <>
              <Divider />
              <h3>Entitlements History</h3>
              <List
                className="added-services"
                loading={isLoadingEntitlements}
                pagination={{
                  defaultPageSize: 10,
                  total: Number(entitlementsHistory?.headers['x-total-count']),
                  pageSizeOptions: ['10', '20', '50', '100'],
                  showSizeChanger: true,
                  onChange: (pageList: any, pageSizeList: any) => {
                    setPage(pageList - 1);
                    setPageSize(pageSizeList);
                  },
                  hideOnSinglePage:
                    Number(entitlementsHistory?.headers['x-total-count']) <=
                    pageSize,
                }}
              >
                {entitlementsHistory?.data.map((entitlements: any) => (
                  <Collapse
                    onChange={onChange}
                    key={entitlements.contractId + entitlements.updatedAt}
                  >
                    <Panel
                      header={`Snapshot - ${convertDate(
                        entitlements.updatedAt
                      )}`}
                      key={entitlements.contractId}
                    >
                      <Row>
                        <Col span={24}>
                          <ul className="flex  space-x-4">
                            <li>
                              <strong>Auto Updated:</strong>{' '}
                              {entitlements.autoUpdated ? (
                                <Tag color="green">Auto Updated</Tag>
                              ) : (
                                <Tag color="default">-</Tag>
                              )}
                            </li>
                            <li>
                              <strong>Entitlement Updated:</strong>{' '}
                              {entitlements.serviceUpdated ? (
                                <Tag color="green">Entitlement Updated</Tag>
                              ) : (
                                <Tag color="default">-</Tag>
                              )}
                            </li>
                            <li>
                              <strong>Asset Updated:</strong>{' '}
                              {entitlements.assetUpdated ? (
                                <Tag color="green">Asset Updated</Tag>
                              ) : (
                                <Tag color="default">-</Tag>
                              )}
                            </li>
                          </ul>
                        </Col>
                      </Row>
                      <div className="service-history-lists">
                        {entitlements.addedServices.length !== 0 && (
                          <div className="service-list">
                            <List
                              header={<div>Added</div>}
                              className="added-services"
                              bordered
                              dataSource={entitlements.addedServices}
                              renderItem={(item: any) => (
                                <List.Item>{item}</List.Item>
                              )}
                            />
                          </div>
                        )}
                        {entitlements.removedServices.length !== 0 && (
                          <div className="service-list">
                            <List
                              header={<div>Removed</div>}
                              className="removed-services"
                              bordered
                              dataSource={entitlements.removedServices}
                              renderItem={(item: any) => (
                                <List.Item>{item}</List.Item>
                              )}
                            />
                          </div>
                        )}
                        {entitlements.services.length !== 0 && (
                          <div className="service-list">
                            <List
                              header={<div>Active</div>}
                              className="current-services"
                              bordered
                              dataSource={entitlements.services}
                              renderItem={(item: any) => (
                                <List.Item>{item}</List.Item>
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </Panel>
                  </Collapse>
                ))}
              </List>
            </>
          ) : (
            <Empty description="No entitlements history associated with this contract" />
          )}
        </Card>
      </Col>
      <Modal
        title={`Revert Contract to Snapshot: ${dateId}`}
        open={revertModal}
        onCancel={() => setRevertModal(false)}
        footer={[
          <Button key="back" size="large" onClick={() => setRevertModal(false)}>
            Close
          </Button>,
          <Button
            key="accept"
            type="primary"
            size="large"
            onClick={() => setRevertSnapshot}
          >
            Accept
          </Button>,
        ]}
      >
        <p>Are you sure you want to revert this contract to this snapshot?</p>
        {setRevertSnapshot.isLoading && <Spin />}
      </Modal>
    </Row>
  );
};

export default ContractEntitlements;
