import React, { useEffect, useState } from 'react';

import { Spin } from 'antd';

import useAuth from '../hooks/useAuth';
import useLogout from '../hooks/useLogout';
import useRefreshToken from '../hooks/useRefreshToken';

const PersistLogin = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const logout = useLogout();

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    return () => {
      isMounted = false;
    };
  }, [auth?.accessToken, refresh, logout]);

  return (
    <>
      {isLoading ? (
        <div className="bg-[#eaebee] flex h-full w-full items-center justify-center">
          <Spin size="small" className="mr-4" />
          <span>Loading...</span>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default PersistLogin;
