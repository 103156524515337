export const convertDate = (d: any) => {
  const date = new Date(d);

  // Get the local date and time based on the user's device time zone
  const localDate = date.toLocaleDateString();
  const localTime = date.toLocaleTimeString([], { hour12: false });

  if (localDate === 'Invalid Date' || localTime === 'Invalid Date') {
    return 'Invalid Date';
  }

  // Return the formatted date and time
  return `${localDate}-${localTime}`;
};

export const convertMillisecondsToDHMS = (milliseconds: number): string => {
  const secondsInMillisecond = 1000;
  const minutesInMillisecond = secondsInMillisecond * 60;
  const hoursInMillisecond = minutesInMillisecond * 60;
  const daysInMillisecond = hoursInMillisecond * 24;

  const days = Math.floor(milliseconds / daysInMillisecond);
  milliseconds -= days * daysInMillisecond;

  const hours = Math.floor(milliseconds / hoursInMillisecond);
  milliseconds -= hours * hoursInMillisecond;

  const minutes = Math.floor(milliseconds / minutesInMillisecond);
  milliseconds -= minutes * minutesInMillisecond;

  const seconds = Math.floor(milliseconds / secondsInMillisecond);
  milliseconds -= seconds * secondsInMillisecond;

  const timeUnits: string[] = [];

  if (days > 0) {
    timeUnits.push(`${days}d`);
  }
  if (hours > 0) {
    timeUnits.push(`${hours}h`);
  }
  if (minutes > 0) {
    timeUnits.push(`${minutes}m`);
  }
  if (seconds > 0) {
    timeUnits.push(`${seconds}s`);
  }
  if (milliseconds > 0) {
    timeUnits.push(`${milliseconds}ms`);
  }

  return timeUnits.join(':') || '0 milliseconds';
};
