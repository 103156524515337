import React, { useEffect, useRef, useState } from 'react';

import { UserOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Breadcrumb,
  Card,
  Avatar,
  Button,
  Input,
  RadioChangeEvent,
  Radio,
  Menu,
  MenuProps,
  Form,
} from 'antd';
import './NCRUserProfile.scss';
import Title from 'antd/lib/typography/Title';
import { Link } from 'react-router-dom';

import { axiosPrivate } from '../../../api/axios';
import uploadIcon from '../../../assets/images/upload-icon.png';
import { UserType } from '../../../models/UserType';
import * as ROUTES from '../../../Routes/Routes';

const NCRUserProfile = () => {
  const [itemDisabled, setItemDisabled] = useState<boolean>(true);
  const userData = sessionStorage.getItem('user');
  const [userID, setUserID] = useState<any>(
    userData ? JSON.parse(userData) : null
  );
  const [user, setUser] = useState<UserType>();
  const [newFirstName, setNewFirstName] = useState<string>();
  const [newLastName, setNewLastName] = useState<string>();
  const [newUsername, setNewUsername] = useState<string>();

  const getUserProfile = async () => {
    try {
      axiosPrivate({
        method: 'GET',
        url: `users/profile`,
      }).then((response) => {
        console.log('RESPONSE', response.data);
        setUser(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const checkDiffInValues = () => {
    if (
      (newFirstName !== user?.firstName && newFirstName) ||
      (newLastName !== user?.lastName && newLastName) ||
      (newUsername !== user?.email && newUsername)
    ) {
      return true;
    }
    return false;
  };

  const updateUserCredentials = async (
    firstNameData: string | undefined,
    lastNameData: string | undefined,
    usernameData: string | undefined
  ) => {
    try {
      axiosPrivate({
        method: 'PUT',
        url: `users/${userID.sub}`,
        data: {
          firstName: firstNameData,
          lastName: lastNameData,
          email: usernameData,
        },
      }).then((response) => {
        setUser(response.data);
      });
      setItemDisabled(!itemDisabled);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);
  return (
    <Row gutter={16}>
      <Col span={8}>
        <Card
          title={
            <Title
              style={{
                fontSize: 'xx-large',
                color: '#5a5a5a',
                marginTop: '25px',
                marginLeft: '15px',
                marginBottom: '30px',
              }}
            >
              User Info
            </Title>
          }
          id="ContractProps"
        >
          <Avatar size={100} icon={<UserOutlined />} />
          <ul className="user-props-list">
            <li>
              <b>First Name: </b> {user?.firstName ? user.firstName : ''}
            </li>
            <li>
              <b>Last Name: </b> {user?.lastName ? user.lastName : ''}
            </li>
            <li>
              <b>Username: </b> {user?.email ? user.email : ''}
            </li>
          </ul>
        </Card>
      </Col>
      <Col span={16}>
        <Card
          title={
            <Title
              style={{
                fontSize: 'xx-large',
                color: '#5a5a5a',
                marginTop: '25px',
                marginLeft: '15px',
              }}
            >
              Update Info
            </Title>
          }
          id="UpdateProps"
        >
          <Form layout="vertical" style={{ marginBottom: '50px' }}>
            <Form.Item label="First Name">
              <Input
                placeholder={user?.firstName}
                disabled={itemDisabled}
                onChange={(e) => setNewFirstName(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Last Name">
              <Input
                placeholder={user?.lastName}
                disabled={itemDisabled}
                onChange={(e) => setNewLastName(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Username or Email">
              <Input
                placeholder={user?.email}
                disabled={itemDisabled}
                onChange={(e) => setNewUsername(e.target.value)}
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            size="middle"
            disabled={itemDisabled ? false : !checkDiffInValues()}
            onClick={
              itemDisabled
                ? () => setItemDisabled(!itemDisabled)
                : () =>
                    updateUserCredentials(
                      newFirstName || user?.firstName,
                      newLastName || user?.lastName,
                      newUsername || user?.email
                    )
            }
          >
            {itemDisabled ? 'Update Info' : 'Save'}
          </Button>
          <Button
            type="default"
            size="middle"
            onClick={() => setItemDisabled(!itemDisabled)}
            style={{
              marginLeft: '10px',
              visibility: itemDisabled ? 'hidden' : 'visible',
            }}
          >
            Cancel
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default NCRUserProfile;
