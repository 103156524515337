import React from 'react';

import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import AssetService from '../../APIServices/AssetService';
import AssetPendingTasks from '../AssetsPendingTasks/AssetsPendingTasks';

interface RetireAssetProps {
  singleAsset?: any;
  openRetireAssetModal?: any;
  setOpenRetireAssetModal?: any;
  pendingItems?: any;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const RetireAsset: React.FC<RetireAssetProps> = ({
  singleAsset,
  openRetireAssetModal,
  setOpenRetireAssetModal,
  pendingItems,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();
  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  const setRetireAsset = useMutation({
    mutationFn: () => AssetService.retireAsset(singleAsset.id),
    onSuccess: () => {
      openNotificationWithIcon('success', 'Success', 'Asset retire successful');
      setOpenRetireAssetModal(false);
      queryClient.clear();
    },
    onError: () => {
      openNotificationWithIcon('error', 'Failed', 'Asset retire failed');
    },
  });

  return (
    <Modal
      title="Retire Asset"
      open={openRetireAssetModal}
      onCancel={() => setOpenRetireAssetModal(false)}
      footer={[
        <Button
          key="back"
          size="large"
          onClick={() => setOpenRetireAssetModal(false)}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          size="large"
          danger
          loading={setRetireAsset.isLoading}
          onClick={() => setRetireAsset.mutate(singleAsset.id)}
          hidden
        >
          Retire Asset
        </Button>,
      ]}
    >
      <div>
        <h1 style={{ fontSize: '15px' }}>
          <ExclamationCircleOutlined
            style={{ marginRight: '10px', color: 'red' }}
          />
          Are you sure you want to Remove these ATM(s)? This action will remove
          the selected ATMs and all associated information from the ledger. This
          action cannot be undone!
        </h1>
      </div>
    </Modal>
  );
};

export default RetireAsset;
