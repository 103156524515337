import { axiosPrivate } from '../api/axios';
import { SiteType } from '../models/SiteType';

export default class SiteService {
  static async getSites(
    page: number,
    size: number,
    filters?: string,
    customerId?: string
  ) {
    return axiosPrivate({
      url: `/sites?customerId=${customerId}${filters ? `&${filters}` : ''}`,
      method: 'GET',
      params: {
        page,
        size,
      },
    });
  }

  static async getSiteById(id: string) {
    return axiosPrivate({
      url: `/sites/${id}`,
      method: 'GET',
    });
  }

  static async createSite(site: SiteType) {
    return axiosPrivate({
      url: `/sites`,
      method: 'POST',
      data: site,
    });
  }

  static async updateSite(site: SiteType) {
    return axiosPrivate({
      url: `/sites/${site.id}`,
      method: 'PUT',
      data: site,
    });
  }
}
