/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';

import {
  DollarCircleOutlined,
  ExclamationCircleOutlined,
  FileExclamationOutlined,
  RedoOutlined,
  RightSquareOutlined,
} from '@ant-design/icons';
import {
  Button,
  Row,
  Col,
  Card,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Input,
  Select,
  Empty,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { FilterValue } from 'antd/lib/table/interface';
import { QueryCache, QueryClient, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import CustomerService from '../../../APIServices/CustomerService';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { CustomerType } from '../../../models/CustomerType';

interface tableData {
  searchFields?: { id: string; value: string }[];
}

const NCRCustomerTable: React.FC<tableData> = ({ searchFields }) => {
  const [searchField, setSearchField] = useState<string>('Select Field');
  const { Search } = Input;
  const { Option } = Select;
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const getSingleCustomerView = (customerID: string) => {
    navigate(`/ncr/customers/${customerID}/overview`);
    sessionStorage.setItem('customer', customerID);
  };
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filters, setFilters] = React.useState('');

  const columns: ColumnsType<CustomerType> = [
    {
      title: 'Customer No',
      dataIndex: 'customerNbr',
      key: 'customerNbr',
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'Action Required',
      dataIndex: 'actionRequiredAssetCount',
      key: 'actionRequiredAssetCount',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        a.actionRequiredAssetCount! - b.actionRequiredAssetCount!,
      render: (_, record: any) => {
        if (record.actionRequiredAssetCount >= 1) {
          return (
            <Tag color="volcano" key={record.actionRequired}>
              {record.actionRequiredAssetCount} <ExclamationCircleOutlined />
            </Tag>
          );
        }
        return (
          <Tag color="green" key={record.actionRequired}>
            0
          </Tag>
        );
      },
    },
    {
      title: 'Auto Moved',
      dataIndex: 'autoMovedAssetCount',
      key: 'autoMovedAssetCount',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.autoMovedAssetCount! - b.autoMovedAssetCount!,
      render: (_, record: any) => {
        if (record.autoMovedAssetCount >= 1) {
          return (
            <Tag
              color="blue"
              key={`${record.autoMovedAssetCount}-autoMovedAssetCount`}
            >
              {record.autoMovedAssetCount} <RightSquareOutlined />
            </Tag>
          );
        }
        return (
          <Tag color="green" key={record.autoMoved}>
            0
          </Tag>
        );
      },
    },

    {
      title: 'Require Contract',
      dataIndex: 'requireContractAssetCount',
      key: 'requireContractAssetCount',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        a.requireContractAssetCount! - b.requireContractAssetCount!,
      render: (_, record: any) => {
        if (record.requireContractAssetCount >= 1) {
          return (
            <Tag
              color="volcano"
              key={`${record.requireContractAssetCount}-requireContractAssetCount`}
            >
              {record.requireContractAssetCount} <FileExclamationOutlined />
            </Tag>
          );
        }
        return (
          <Tag
            color="green"
            key={`${record.requireContractAssetCount}-requireContractAssetCount`}
          >
            0
          </Tag>
        );
      },
    },

    {
      title: 'Updated Asset',
      dataIndex: 'autoUpdatedAssetCount',
      key: 'autoUpdatedAssetCount',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.autoUpdatedAssetCount! - b.autoUpdatedAssetCount!,
      render: (_, record: any) => {
        if (record.autoUpdatedAssetCount >= 1) {
          return (
            <Tag color="blue" key={record.autoUpdatedAssetCount}>
              {record.autoUpdatedAssetCount} <RedoOutlined />
            </Tag>
          );
        }
        return (
          <Tag color="green" key={record.autoUpdatedAssetCount}>
            0
          </Tag>
        );
      },
    },
    {
      title: 'Asset Count',
      dataIndex: 'assetCount',
      key: 'assetCount',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.assetCount! - b.assetCount!,
    },
    {
      title: 'Contract Count',
      dataIndex: 'contractCount',
      key: 'contractCount',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.contractCount! - b.contractCount!,
    },
    {
      title: 'Site Count',
      dataIndex: 'siteCount',
      key: 'siteCount',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.siteCount! - b.siteCount!,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => getSingleCustomerView(record.id!)}
            size="small"
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  const { data: customers, isLoading } = useQuery(
    ['customers', page, pageSize, filters],
    () => CustomerService.getCustomers(page, pageSize, filters)
  );

  const handleSearch = (e: any) => {
    setPage(0);
    setPageSize(10);
    const encodedValue = encodeURIComponent(e.trim());
    setFilters(`${searchField}=${encodedValue}`);
  };

  const handleChange = (pagination: any) => {
    setPage(pagination.current - 1);
    setPageSize(pagination.pageSize);
  };

  const selectBefore = (
    <div>
      <Select
        defaultValue="Select Field"
        placeholder="Select Field"
        value={searchField}
        onChange={(e) => setSearchField(e)}
        style={{ width: 200 }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {searchFields?.map((item: { id: string; value: string }) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.value}
            </Option>
          );
        })}
      </Select>
    </div>
  );

  return (
    <Row className="h-full">
      <Col span={24}>
        <Card title="Customers" className="h-full">
          <div className="search-bar">
            <Search
              addonBefore={selectBefore}
              placeholder="Search"
              enterButton="Search"
              size="large"
              allowClear
              onSearch={(e) => handleSearch(e)}
            />
          </div>
          <br />

          <Table
            columns={columns}
            rowKey={(record) => record.customerNbr!}
            dataSource={customers?.data}
            loading={isLoading}
            onChange={handleChange}
            locale={{
              emptyText: <Empty description="No customers" />,
            }}
            className="customer-table"
            pagination={{
              defaultPageSize: 10,
              current: page + 1,
              pageSize,
              total: Number(customers?.headers['x-total-count']),
              pageSizeOptions: ['10', '20', '50', '100'],
              showSizeChanger: true,
              hideOnSinglePage:
                Number(customers?.headers['x-total-count']) <= pageSize,
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default NCRCustomerTable;
