/* eslint-disable no-prototype-builtins */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useEffect, useState } from 'react';

import {
  Button,
  Row,
  Col,
  Card,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Empty,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useNavigate } from 'react-router-dom';

import { AssetMoveHistoryType } from '../../../models/AssetMoveHistoryType';
import { AssetType } from '../../../models/AssetType';
import { CustomerType } from '../../../models/CustomerType';
import { samplePinDataObj } from '../../../models/PinDataType';
import { ServiceType } from '../../../models/ServiceType';
import { SiteType } from '../../../models/SiteType';
import { convertDate } from '../../../utils/ConvertDate';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface tableDataProps {
  currentTableData?: AssetType;
  pastTableData: AssetMoveHistoryType[];
  reconData: SiteType[];
  columnParams?: ColumnsType<SiteType>;
  isLoading?: boolean;
}

const MoveHistoryTable: React.FC<tableDataProps> = ({
  currentTableData,
  pastTableData,
  reconData,
  columnParams,
  isLoading,
}) => {
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const getTableData = () => {
    const newTableData = [];
    let isPreviousSite = {};

    if (pastTableData) {
      for (let i = 0; i < pastTableData?.length; i += 1) {
        newTableData.push(pastTableData[i]);
        if (i === pastTableData.length - 1) {
          if (pastTableData[i]?.previousSite?.addressLine1) {
            isPreviousSite = { isPreviousSite: pastTableData[i]?.previousSite };
            newTableData.push(isPreviousSite);
          }
        }
      }
    }

    return newTableData;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Location Status',
      dataIndex: 'siteStatus',
      key: 'siteStatus',
      render: (text, record) => {
        let color = 'gray';
        text = 'NO STATUS';
        if (record?.locationVerificationStatus === 'Verified') {
          color = 'green';
          text = 'Verified';
        } else if (record?.locationVerificationStatus === 'UnVerified') {
          color = 'volcano';
          text = 'UnVerified';
        } else {
          color = 'volcano';
          text = 'UnVerified';
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: 'Site Address',
      dataIndex: 'addressLine1',
      key: 'addressLine1',
      render: (text, record) => {
        return record?.site?.addressLine1;
      },
    },
    {
      title: 'NCR Site ID',
      dataIndex: 'ncrSiteId',
      key: 'ncrSiteId',
      render: (text, record) => {
        return record?.site?.ncrSiteId;
      },
    },
    {
      title: 'Site Coordinates',
      dataIndex: 'ncrSiteId',
      key: 'ncrSiteId',
      render: (text, record) => {
        if (record?.site?.geotag) {
          return record?.site?.geotag[0]?.concat(', ', record?.site?.geotag[1]);
        }
        return 'no data';
      },
    },
    {
      title: 'Scan / Update Date',
      dataIndex: 'lastScannedTimeStamp',
      key: 'lastScannedTimeStamp',
      render: (text, record) => {
        if (record?.lastScannedTimeStamp === undefined) {
          return <span>Updated At: {convertDate(record?.updatedAt)}</span>;
        }
        return <span>Scan Date: {text}</span>;
      },
    },
  ];

  const columnsHistory: ColumnsType<any> = [
    {
      title: 'Location Status',
      dataIndex: 'siteStatus',
      key: 'siteStatus',
      render: (text, record) => {
        let color = 'gray';
        text = 'NO STATUS';
        if (record.hasOwnProperty('isPreviousSite')) {
          if (record?.locationVerificationStatus === 'Verified') {
            color = 'green';
            text = 'Verified';
          } else if (record?.locationVerificationStatus === 'UnVerified') {
            color = 'volcano';
            text = 'UnVerified';
          } else {
            color = 'volcano';
            text = 'UnVerified';
          }
        }

        if (record?.locationVerificationStatus === 'Verified') {
          color = 'green';
          text = 'Verified';
        } else if (record?.locationVerificationStatus === 'UnVerified') {
          color = 'volcano';
          text = 'UnVerified';
        } else {
          color = 'volcano';
          text = 'UnVerified';
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: 'Site Address',
      dataIndex: 'addressLine1',
      key: 'addressLine1',
      render: (text, record) => {
        if (record.hasOwnProperty('isPreviousSite')) {
          return record?.isPreviousSite?.addressLine1;
        }
        return record?.site?.addressLine1;
      },
    },
    {
      title: 'NCR Site ID',
      dataIndex: 'ncrSiteId',
      key: 'ncrSiteId',
      render: (text, record) => {
        if (record.hasOwnProperty('isPreviousSite')) {
          return record?.isPreviousSite?.ncrSiteId;
        }
        return record?.site?.ncrSiteId;
      },
    },
    {
      title: 'Site Coordinates',
      dataIndex: 'postalCode',
      key: 'postalCode',
      render: (text, record) => {
        if (record.hasOwnProperty('isPreviousSite')) {
          if (record?.isPreviousSite?.geotag) {
            return record?.isPreviousSite?.geotag[0]?.concat(
              ', ',
              record.isPreviousSite?.geotag[1]
            );
          }
        }

        if (record?.site?.geotag) {
          return record?.site?.geotag[0]?.concat(', ', record?.site?.geotag[1]);
        }
        return 'No Site Coordinates';
      },
    },
    {
      title: 'Scan / Update Date',
      dataIndex: 'lastScannedTimeStamp',
      key: 'lastScannedTimeStamp',
      render: (text, record) => {
        if (record.hasOwnProperty('isPreviousSite')) {
          if (record?.lastScannedTimeStamp === undefined) {
            return (
              <span>
                Updated At: {convertDate(record?.isPreviousSite?.updatedAt)}
              </span>
            );
          }
          return <span>Scan Date: {record?.lastScannedTimeStamp}</span>;
        }
        if (record.lastScannedTimeStamp === undefined) {
          return <span>Updated At: {convertDate(record?.updatedAt)}</span>;
        }
        return <span>Scan Date: {record?.lastScannedTimeStamp}</span>;
      },
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title="Current Location">
          <Table
            columns={columnParams || columns}
            rowKey={(record) => record?.ncrSiteId}
            dataSource={[currentTableData]}
            pagination={false}
            loading={isLoading}
            locale={{
              emptyText: <Empty description="No location data" />,
            }}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card title="Location History" style={{ marginTop: '20px' }}>
          <Table
            columns={columnsHistory}
            rowKey={(record) => record?.id}
            dataSource={getTableData()}
            pagination={tableParams.pagination}
            loading={isLoading}
            locale={{
              emptyText: (
                <Empty description="No history associated with this asset" />
              ),
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default MoveHistoryTable;
