/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useEffect, useState } from 'react';

import {
  Button,
  Row,
  Col,
  Card,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Select,
  Input,
  Empty,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useNavigate, useLocation } from 'react-router-dom';

import { useClient } from '../../../Context/ClientContextProvider';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { ContractType } from '../../../models/ContractType';
import { CustomerType } from '../../../models/CustomerType';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface tableDataProps {
  tableData?: ContractType[];
  searchFields?: { id: string; value: string }[];
  isCustomer?: boolean;
  assetId?: string;
  customerId?: string;
}

const NCRAssetsContractsTable: React.FC<tableDataProps> = ({
  tableData,
  searchFields,
  isCustomer,
  assetId,
  customerId,
}) => {
  const [data, setData] = useState<ContractType[]>(tableData!);
  const { auth } = useAuth();
  const [addNewContractOpen, setAddNewContractOpen] = useState(false);
  const location = useLocation();
  const [searchField, setSearchField] = useState('Select Field');
  const [loadingTable, setLoadingTable] = useState(false);
  const { Search } = Input;
  const { Option } = Select;
  const axiosPrivate = useAxiosPrivate();
  const dashboard = location.pathname.split('/')[1];
  const lastSegment = location.pathname.split('/').pop();
  const clientID = sessionStorage.getItem('customer');
  const [dropdownItems, setDropdownItems] = useState([
    'Initialized',
    'Active',
    'PendingApproval',
    'Issue',
  ]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const navigate = useNavigate();

  const getSingleContractView = (contractID: string) => {
    if (isCustomer && auth.roles.includes('ncr-admin')) {
      sessionStorage.setItem('customer', customerId!);
      navigate(`/ncr/customers/${customerId}/contracts/${contractID}`);
    } else if (auth.roles.includes('ncr-admin')) {
      navigate(`/ncr/contracts/${contractID}`);
    } else if (auth.roles.includes('ncr-client')) {
      navigate(`/dashboard/contracts/${contractID}`);
    }
  };

  useEffect(() => {
    setData(tableData!);
  }, [tableData, assetId]);

  const columns: ColumnsType<ContractType> = [
    {
      title: 'Contract Number',
      dataIndex: 'contractNbr',
      key: 'contractNbr',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Contract Modifier',
      dataIndex: 'contractModifier',
      key: 'contractModifier',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },

    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, { status }) => {
        let color = 'green';
        text = 'Active';
        if (status === 'EXPIRED') {
          color = 'volcano';
          text = 'Expired';
        }
        if (status === 'HOLD') {
          color = 'yellow';
          text = 'Hold';
        }
        if (status === 'TERMINATED') {
          color = 'red';
          text = 'Terminated';
        }
        return (
          <Tag color={color} key={status}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record: ContractType) => (
        <Button
          type="primary"
          onClick={() => getSingleContractView(record.id!)}
          size="small"
        >
          View
        </Button>
      ),
    },
  ];

  const onSearch = async (value: string) => {
    setLoadingTable(true);
    let apiPath: string;
    const encodedValue = encodeURIComponent(value.trim());
    if (isCustomer) {
      apiPath = `?customerId=${clientID}&${searchField}=${encodedValue}`;
    } else {
      apiPath = `?${searchField}=${encodedValue}`;
    }
    try {
      axiosPrivate({
        method: 'GET',
        url: `contracts${apiPath}`,
      }).then((response) => {
        setData(response.data);
        setLoadingTable(false);
      });
    } catch (error: any) {
      console.log(error);
      setLoadingTable(false);
    }
  };

  const selectBefore = (
    <div>
      <Select
        defaultValue="Select Field"
        placeholder="Select Field"
        value={searchField}
        onChange={(e) => setSearchField(e)}
        style={{ width: 200 }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {searchFields?.map((item) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.value}
            </Option>
          );
        })}
      </Select>
    </div>
  );
  return (
    <Row className="h-full">
      <Col span={24}>
        <Card title="Contracts" className="h-full">
          <Table
            columns={columns}
            rowKey={(record) => record.id!}
            dataSource={data}
            loading={loading}
            pagination={false}
            locale={{
              emptyText: <Empty description="No assets" />,
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default NCRAssetsContractsTable;
