/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-boolean-value */
import React, { useState } from 'react';

import {
  Row,
  Col,
  Button,
  Drawer,
  Select,
  Form,
  Input,
  notification,
  Modal,
  TablePaginationConfig,
} from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import AssetService from '../../../APIServices/AssetService';
import CustomerService from '../../../APIServices/CustomerService';
import { useClient } from '../../../Context/ClientContextProvider';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { AssetType } from '../../../models/AssetType';
import ClientSitesTable from '../../NCRClient/ClientSitesTable/ClientSitesTable';
import SiteSelector from '../../SiteSelector/SiteSelector';
import NCRAssetTable from '../NCRAssetTable/NCRAssetTable';

import './NCRAssets.scss';

type NotificationType = 'success' | 'info' | 'warning' | 'error';
interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}
const NCRAssets = () => {
  const [addNewAssetOpen, setAddNewAssetOpen] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const navigate = useNavigate();
  const [selectedSite, setSelectedSite] = useState<any>();
  const queryClient = useQueryClient();
  const {
    setValue,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors, isSubmitSuccessful, isValid },
  } = useForm({
    defaultValues: {
      assetType: 'ATM',
      serialNbr: '',
      itemInstance: '',
      productId: '',
      softwareKeyId: '',
      scanLatitude: '',
      scanLongitude: '',
      retired: null,
      active: null,
      customer: {
        id: null,
      },
      site: {
        id: '',
      },
    },
  });

  const emptyFieldsOnCancel = () => {
    reset();
    setAddNewAssetOpen(!addNewAssetOpen);
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  const onCreateAsset = async (assetData: AssetType) => {
    const { data: response } = await AssetService.createAsset(assetData);
    return response;
  };

  const mutation = useMutation(onCreateAsset, {
    onSuccess: () => {
      openNotificationWithIcon(
        'success',
        'Asset Created Successfully',
        'Asset has been created successfully'
      );
      setAddNewAssetOpen(false);
      queryClient.invalidateQueries('assets');
    },
    onError: (error: any) => {
      openNotificationWithIcon(
        'error',
        `${error?.response.data.message}`,
        'Asset creation failed'
      );
    },
  });

  const onSubmit = (data: any) => {
    mutation.mutate(data);
    reset();
  };

  const getSingleAssetView = (assetID: string) => {
    navigate(`/ncr/assets/${assetID}`);
  };

  const { data: customers, isLoading } = useQuery(['customers'], () =>
    CustomerService.getCustomers(0, 20)
  );

  const selectSiteForForm = (id: string) => {
    setSelectedSite(id);
    setOpenSearchModal(false);
    setValue('site.id', id, { shouldValidate: true });
  };

  return (
    <Row gutter={16}>
      {contextHolder}
      <Col span={24}>
        <div className="cta-toolbar">
          <Button
            type="primary"
            onClick={() => setAddNewAssetOpen(!addNewAssetOpen)}
            hidden
          >
            Create New Asset
          </Button>
        </div>
      </Col>

      <Col span={24}>
        <NCRAssetTable
          tableAction={getSingleAssetView}
          tableTitle="Assets"
          isCustomer={false}
        />
      </Col>
      <Drawer
        title="Create New Asset"
        placement="right"
        destroyOnClose
        closable
        onClose={() => setAddNewAssetOpen(!addNewAssetOpen)}
        size="large"
        open={addNewAssetOpen}
        footer={
          <>
            <Button onClick={() => emptyFieldsOnCancel()} type="default">
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              form="createAsset"
              disabled={!isValid}
              loading={createLoading}
              style={{ marginLeft: '10px' }}
              onClick={() => setAddNewAssetOpen(!addNewAssetOpen)}
            >
              Create Asset
            </Button>
          </>
        }
      >
        <form id="createAsset" onSubmit={handleSubmit(onSubmit)}>
          <Form.Item
            label={
              <span>
                <span>Customer ID</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="customer.id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  showSearch
                  placeholder="Select Customer"
                  optionFilterProp="children"
                  allowClear
                  filterOption={(input, option: any) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {customers?.data.map((customer: any) => (
                    <Select.Option key={customer.id} value={customer.id}>
                      {customer.customerName}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
            {errors.customer?.id?.type === 'required' && (
              <p role="alert">Customer is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Site ID</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="site.id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div className="search-field">
                  <Input
                    {...field}
                    placeholder="Enter Site Number"
                    aria-required="true"
                    onClick={() => setOpenSearchModal(true)}
                  />
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => setOpenSearchModal(true)}
                  >
                    Search
                  </Button>
                </div>
              )}
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Serial Number</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="serialNbr"
              control={control}
              rules={{ required: true, minLength: 3 }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Serial Number"
                  aria-required="true"
                />
              )}
            />
            {errors.serialNbr?.type === 'required' && (
              <p role="alert">Serial Number is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Product ID</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="productId"
              control={control}
              rules={{ required: true, minLength: 3 }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Product ID"
                  aria-required="true"
                />
              )}
            />
            {errors.productId?.type === 'required' && (
              <p role="alert">Product ID is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Item Instance</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="itemInstance"
              control={control}
              rules={{ required: true, minLength: 3 }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Item Instance"
                  aria-required="true"
                />
              )}
            />
            {errors.itemInstance?.type === 'required' && (
              <p role="alert">Item Instance is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Software Key ID</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="softwareKeyId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Software Key ID"
                  aria-required="true"
                />
              )}
            />
            {errors.softwareKeyId?.type === 'required' && (
              <p role="alert">Software Key ID is required</p>
            )}
          </Form.Item>
          <Form.Item label="Asset Type">
            <Controller
              name="assetType"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Asset Type"
                  aria-required="true"
                  value="ATM"
                />
              )}
            />
          </Form.Item>
          <Form.Item label="Retired">
            <Controller
              name="retired"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Select Retired"
                  defaultValue={field.value}
                >
                  <Select.Option value="true">True</Select.Option>
                  <Select.Option value="false">False</Select.Option>
                </Select>
              )}
            />
            {errors.retired?.type === 'required' && (
              <p role="alert">retired is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Active</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="active"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} placeholder="Select Active">
                  <Select.Option value="true">True</Select.Option>
                  <Select.Option value="false">False</Select.Option>
                </Select>
              )}
            />
            {errors.active?.type === 'required' && (
              <p role="alert">Active is required</p>
            )}
          </Form.Item>
          <Form.Item label="Scan Latitude">
            <Controller
              name="scanLatitude"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Scan Latitude"
                  aria-required="true"
                />
              )}
            />
          </Form.Item>
          <Form.Item label="Scan Longitude">
            <Controller
              name="scanLongitude"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Scan Longitude"
                  aria-required="true"
                />
              )}
            />
          </Form.Item>
        </form>
      </Drawer>
      <Modal
        title="Select Site"
        open={openSearchModal}
        destroyOnClose
        onCancel={() => setOpenSearchModal(false)}
        width="80%"
        footer={[
          <Button
            key="back"
            size="large"
            onClick={() => setOpenSearchModal(false)}
          >
            Close
          </Button>,
        ]}
      >
        <ClientSitesTable
          tableAction={selectSiteForForm}
          customerId={getValues('customer.id')!}
          siteSelector
          isCustomer
        />
      </Modal>
    </Row>
  );
};

export default NCRAssets;
