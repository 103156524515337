/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';

import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import {
  Row,
  Col,
  Button,
  Drawer,
  Form,
  Input,
  notification,
  Select,
} from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import CustomerService from '../../../APIServices/CustomerService';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { CustomerType } from '../../../models/CustomerType';
import NCRClientTable from '../NCRCustomerTable/NCRCustomerTable';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const NCRCustomers = () => {
  const [addNewCustomerOpen, setAddNewCustomerOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitSuccessful, isValid },
  } = useForm({
    defaultValues: {
      customerNbr: '',
      customerName: '',
      contactPerson: '',
      primaryEmail: '',
      primaryPhone: '',
      hqAddressline1: '',
      hqAddressline2: '',
      hqState: '',
      hqCity: '',
      hqProvince: '',
      hqPostalcode: '',
      hqCounty: '',
      hqCountry: '',
    },
  });

  const emptyFieldsOnCancel = () => {
    reset();
    setAddNewCustomerOpen(!addNewCustomerOpen);
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  const onCreateCustomer = async (formData: CustomerType) => {
    const { data: response } = await CustomerService.createCustomer(formData);
    return response;
  };

  const mutation = useMutation(onCreateCustomer, {
    onSuccess: () => {
      openNotificationWithIcon(
        'success',
        'Success',
        'Customer created successfully'
      );
      queryClient.invalidateQueries('customers');
      setAddNewCustomerOpen(false);
    },
    onError: (error: any) => {
      openNotificationWithIcon(
        'error',
        `${error?.response.data.message}`,
        'Customer creation failed'
      );
    },
  });

  const onSubmit = (data: any) => {
    mutation.mutate(data);
    reset();
  };

  return (
    <Row gutter={16}>
      {contextHolder}
      <Col span={24}>
        <div className="cta-toolbar">
          <Button
            type="primary"
            onClick={() => setAddNewCustomerOpen(!addNewCustomerOpen)}
          >
            Create New Customer
          </Button>
        </div>
      </Col>

      <Col span={24}>
        <NCRClientTable
          searchFields={[
            { id: 'customerName', value: 'Customer Name' },
            { id: 'customerNbr', value: 'Customer Number' },
          ]}
        />
      </Col>
      <Drawer
        title="Create New Customer"
        placement="right"
        closable
        onClose={() => setAddNewCustomerOpen(!addNewCustomerOpen)}
        size="large"
        open={addNewCustomerOpen}
        footer={
          <>
            <Button onClick={() => emptyFieldsOnCancel()} type="default">
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              form="createCustomer"
              disabled={!isValid}
              loading={!!mutation.isLoading}
              style={{ marginLeft: '10px' }}
            >
              Create Customer
            </Button>
          </>
        }
      >
        <form id="createCustomer" onSubmit={handleSubmit(onSubmit)}>
          <Form.Item
            label={
              <span>
                <span>Customer Number</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="customerNbr"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Customer Number"
                  aria-required="true"
                />
              )}
            />
            {errors.customerNbr?.type === 'required' && (
              <p role="alert">Customer Number is required</p>
            )}
          </Form.Item>

          <Form.Item
            label={
              <span>
                <span>Customer Name</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="customerName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Customer Name"
                  aria-required="true"
                />
              )}
            />
            {errors.customerName?.type === 'required' && (
              <p role="alert">Customer Name is required</p>
            )}
          </Form.Item>

          <Form.Item label="Contact Person">
            <Controller
              name="contactPerson"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Contact Person"
                  aria-required="true"
                />
              )}
            />
          </Form.Item>

          <Form.Item
            label={
              <span>
                <span>Primary Email</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="primaryEmail"
              control={control}
              rules={{ required: true, pattern: /\S+@\S+\.\S+/i }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Primary Email"
                  aria-required="true"
                  type="email"
                />
              )}
            />
            {errors.primaryEmail?.type === 'required' && (
              <p role="alert">Primary Email is required</p>
            )}
          </Form.Item>

          <Form.Item label="Primary Phone">
            <Controller
              name="primaryPhone"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Primary Phone"
                  aria-required="true"
                  type="phone"
                />
              )}
            />
          </Form.Item>

          <Form.Item
            label={
              <span>
                <span>Address Line 1</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="hqAddressline1"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Address Line 1"
                  aria-required="true"
                />
              )}
            />
            {errors.hqAddressline1?.type === 'required' && (
              <p role="alert">Address Line 1 is required</p>
            )}
          </Form.Item>

          <Form.Item label="Address Line 2">
            <Controller
              name="hqAddressline2"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Address Line 2"
                  aria-required="true"
                />
              )}
            />
          </Form.Item>

          <Form.Item
            label={
              <span>
                <span>City</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="hqCity"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter City"
                  aria-required="true"
                />
              )}
            />
            {errors.hqCity?.type === 'required' && (
              <p role="alert">City is required</p>
            )}
          </Form.Item>

          <Form.Item
            label={
              <span>
                <span>State</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="hqState"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter State"
                  aria-required="true"
                />
              )}
            />

            {errors.hqState?.type === 'required' && (
              <p role="alert">State is required</p>
            )}
          </Form.Item>

          <Form.Item
            label={
              <span>
                <span>Zip Code</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="hqPostalcode"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Zip Code"
                  aria-required="true"
                />
              )}
            />
            {errors.hqPostalcode?.type === 'required' && (
              <p role="alert">Zip Code is required</p>
            )}
          </Form.Item>

          <Form.Item
            label={
              <span>
                <span>Country</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="hqCountry"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Country"
                  aria-required="true"
                />
              )}
            />
            {errors.hqCountry?.type === 'required' && (
              <p role="alert">Country is required</p>
            )}
          </Form.Item>

          <Form.Item label="Province">
            <Controller
              name="hqProvince"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Province"
                  aria-required="true"
                />
              )}
            />
            {errors.hqProvince?.type === 'required' && (
              <p role="alert">Province is required</p>
            )}
          </Form.Item>

          <Form.Item label="County">
            <Controller
              name="hqCounty"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter County"
                  aria-required="true"
                />
              )}
            />
            {errors.hqCounty?.type === 'required' && (
              <p role="alert">County is required</p>
            )}
          </Form.Item>
        </form>
      </Drawer>
    </Row>
  );
};

export default NCRCustomers;
