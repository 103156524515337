import React, { useRef, useState } from 'react';

import { UserOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Breadcrumb,
  Card,
  Avatar,
  Button,
  Input,
  RadioChangeEvent,
  Radio,
  Menu,
  MenuProps,
  Form,
} from 'antd';
import './ClientUserProfile.scss';
import Title from 'antd/lib/typography/Title';
import { Link } from 'react-router-dom';

import { axiosPrivate, BASE_URL } from '../../../api/axios';
import uploadIcon from '../../../assets/images/upload-icon.png';
import { UserType } from '../../../models/UserType';
import * as ROUTES from '../../../Routes/Routes';

const ClientUserProfile = () => {
  const [itemDisabled, setItemDisabled] = useState<boolean>(true);
  const userData = sessionStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;
  const appUrl = encodeURIComponent(window.location.toString());
  const clientID = 'ncr-client';

  return (
    <Row gutter={16}>
      <Col span={8}>
        <Card
          title={
            <Title
              style={{
                fontSize: 'xx-large',
                color: '#5a5a5a',
                marginTop: '25px',
                marginLeft: '15px',
              }}
            >
              User Info
            </Title>
          }
          id="ContractProps"
        >
          <Avatar size={100} icon={<UserOutlined />} />
          <ul className="user-props-list">
            <li>
              <b>Name: </b> {user?.given_name.concat(' ', user?.family_name)}
            </li>
            <li>
              <b>Username: </b> {user?.preferred_username}
            </li>
          </ul>
        </Card>
      </Col>
      <Col span={16}>
        <Card
          title={
            <Title
              style={{
                fontSize: 'xx-large',
                color: '#5a5a5a',
                marginTop: '25px',
                marginLeft: '15px',
              }}
            >
              Update Info
              <p
                style={{
                  fontSize: 'small',
                  color: '#777777',
                  marginTop: '10px',
                }}
              >
                Navigate to Keycloak to update your Profile here:
              </p>
            </Title>
          }
          id="UpdateProps"
        >
          <Link
            to={`${process.env
              .REACT_APP_KEYCLOAK_URL!}/realms/ncr/protocol/openid-connect/auth?response_type=code&client_id=${clientID}&redirect_uri=${appUrl}&kc_action=UPDATE_PROFILE`}
          >
            <Button
              type="primary"
              size="large"
              style={{
                marginTop: '25px',
                marginLeft: '15px',
                marginBottom: '20px',
              }}
            >
              Update User Profile
            </Button>
          </Link>
        </Card>
      </Col>
    </Row>
  );
};

export default ClientUserProfile;
