/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Card, Tabs, TabsProps, Upload } from 'antd';

import CSVUploads from '../CSVManagement/CSVUploads/CSVUploads';
import Downloader from '../CSVManagement/Downloader/Downloader';
import Reporting from '../CSVManagement/Reporting/Reporting';
import SliamUploader from '../CSVManagement/SliamUploader/SliamUploader';

const { Dragger } = Upload;

const DataManagement = () => {
  const [current, setCurrent] = React.useState<string>('sliamUploader');
  const items: TabsProps['items'] = [
    {
      label: 'Uploader',
      key: 'sliamUploader',
      children: <SliamUploader tab={current} />,
    },

    {
      label: 'Upload History',
      key: 'upload-history',
      children: <CSVUploads tab={current} />,
    },
    {
      label: 'Downloads',
      key: 'downloader',
      children: <Downloader tab={current} />,
    },
    {
      label: 'Reports',
      key: 'reports',
      children: <Reporting tab={current} />,
    },
  ];

  return (
    <Card title="Data Management">
      <Tabs
        defaultActiveKey="1"
        size="small"
        style={{ marginBottom: 32 }}
        items={items}
        type="line"
        onChange={(key) => setCurrent(key)}
      />
    </Card>
  );
};

export default DataManagement;
