/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */

import React, { useEffect, useState } from 'react';

import { Card, Col, Tabs, Row, TabsProps, notification } from 'antd';
import { useQuery } from 'react-query';

import AssetService from '../../APIServices/AssetService';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import AssetMap from '../AssetMap/AssetMap';
import MoveHistoryTable from '../NCR/SingleAsset/MoveHistoryTable';

import './AssetLocationHistory.scss';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface AssetHistoryProps {
  singleAsset?: any;
  tab?: string;
}

const AssetLocationHistory: React.FC<AssetHistoryProps> = ({
  singleAsset,
  tab,
}) => {
  const [showReconciliation, setShowReconciliation] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const axiosPrivate = useAxiosPrivate();
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };
  const {
    data: moveHistory,
    isLoading,
    refetch,
  } = useQuery(['assetHistory', page, pageSize, singleAsset], () =>
    AssetService.getAssetHistory(
      singleAsset?.id,
      page,
      pageSize,
      'locationUpdated=true'
    )
      .then((res) => res.data)
      .catch((err) => {
        openNotificationWithIcon('error', 'Failed', 'Contract revert failed');
      })
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const moveHistoryItems: TabsProps['items'] = [
    {
      label: 'Table View',
      key: 'asset-services',
      children: (
        <MoveHistoryTable
          isLoading={isLoading}
          pastTableData={moveHistory}
          reconData={[singleAsset!]}
          currentTableData={singleAsset!}
        />
      ),
    },
    {
      label: 'Map View',
      key: 'pending-tasks',
      children: (
        <>
          <AssetMap
            // eslint-disable-next-line
            redData={[singleAsset!]}
            currentSite={singleAsset}
            blueData={moveHistory}
            height={721}
            isLoading={isLoading}
          />
        </>
      ),
    },
  ];
  return (
    <Card title="Location History">
      <Row gutter={20} className="h-full">
        <Col span={24}>
          <Tabs
            defaultActiveKey="0"
            items={moveHistoryItems}
            className="inner-tabs"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default AssetLocationHistory;
