import React, { useState } from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Row, Col, Breadcrumb, TabsProps, Tabs } from 'antd';
import './ClientUserProfile.scss';
import { Link } from 'react-router-dom';

import ClientUserProfile from './ClientUserProfile';
import ClientUserProfilePassword from './ClientUserProfilePassword';
import * as ROUTES from '../../../Routes/Routes';
import NCRUserProfilePassword from '../../NCR/NCRUserProfile/NCRUserProfilePassword';

const ClientUserProfileWrapper = () => {
  const items: TabsProps['items'] = [
    {
      label: 'User Profile',
      key: 'user-profile',
      children: <ClientUserProfile />,
    },
    {
      label: 'Password',
      key: 'user-password',
      children: <ClientUserProfilePassword />,
    },
  ];

  return (
    <>
      <Row gutter={16} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Col span={4}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={ROUTES.DASHBOARD_NCR}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>My Account</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="client-header" id="clientNav">
          <h1 className="page-title">My Account</h1>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="1" tabPosition="left" items={items} />
        </Col>
      </Row>
    </>
  );
};

export default ClientUserProfileWrapper;
