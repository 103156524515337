/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';

import { CloseOutlined, InboxOutlined } from '@ant-design/icons';
import {
  Tag,
  UploadProps,
  Row,
  Col,
  Button,
  Drawer,
  Select,
  Form,
  Input,
  Radio,
  Alert,
  Divider,
  Upload,
  message,
  notification,
  Space,
  Modal,
} from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import SingleAssetClient from './SingleAssetClient';
import states from '../../../assets/dataStates';
import { useClient } from '../../../Context/ClientContextProvider';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { AssetType } from '../../../models/AssetType';
import NCRAssetTable from '../../NCR/NCRAssetTable/NCRAssetTable';
import ClientAssetTable from '../ClientAssetTable/ClientAssetTable';
import ClientSitesTable from '../ClientSitesTable/ClientSitesTable';

const { Dragger } = Upload;

const props: UploadProps = {
  name: 'file',
  multiple: false,
  accept: '.csv',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const ClientAssets = () => {
  const { singleClient } = useClient();
  const { auth } = useAuth();
  const [addNewAssetOpen, setAddNewAssetOpen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [newAsset, setNewAsset] = useState<AssetType>();
  const [data, setData] = useState<AssetType[]>();
  const clientId = sessionStorage.getItem('customer');
  const axiosPrivate = useAxiosPrivate();
  const [errorAssetCreation, setErrorAssetCreation] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState<any>();
  const navigate = useNavigate();
  const {
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitSuccessful, isValid },
  } = useForm({
    defaultValues: {
      assetType: 'ATM',
      serialNbr: '',
      itemInstance: '',
      productId: '',
      softwareKeyId: '',
      scanLatitude: '',
      scanLongitude: '',
      retired: null,
      active: null,
      customer: {
        id: clientId!,
      },
      site: {
        id: '',
      },
      serviceProvider: {
        id: null,
      },
    },
  });

  const emptyFieldsOnCancel = () => {
    reset();
    setAddNewAssetOpen(!addNewAssetOpen);
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  const getSingleAssetView = (assetID: string) => {
    navigate(`${assetID}`);
  };

  const onCreateAsset = async (assetData: any) => {
    setCreateLoading(true);
    axiosPrivate({
      method: 'POST',
      url: '/assets',
      data: assetData,
    })
      .then((response) => {
        openNotificationWithIcon(
          'success',
          'Success',
          'Asset created successfully'
        );
        setCreateLoading(false);
        setAddNewAssetOpen(!addNewAssetOpen);
      })
      .catch((error) => {
        console.log(error?.response.data);
        if (error.response.data.status === 400) {
          openNotificationWithIcon(
            'error',
            `Error please check your form fields`,
            'Asset creation failed'
          );
        } else {
          openNotificationWithIcon(
            'error',
            `${error?.response.data.message}`,
            'Asset creation failed'
          );
        }
        setCreateLoading(false);
      });
  };
  const selectSiteForForm = (id: string) => {
    setSelectedSite(id);
    setOpenSearchModal(false);
    setValue('site.id', id, { shouldValidate: true });
  };

  useEffect(() => {
    setValue('customer.id', clientId!, { shouldValidate: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={16}>
      {/* eslint-disable-next-line no-restricted-globals */}
      <Col span={24}>
        <>
          <Col span={24}>
            <div className="cta-toolbar">
              <Button
                type="primary"
                onClick={() => setAddNewAssetOpen(!addNewAssetOpen)}
                hidden
              >
                Create New Asset
              </Button>
            </div>
          </Col>
          {auth.roles.includes('ncr-admin') ? (
            <NCRAssetTable
              tableAction={getSingleAssetView}
              tableTitle="Assets"
              isCustomer
            />
          ) : (
            <ClientAssetTable
              tableAction={getSingleAssetView}
              tableTitle="Assets"
              isCustomer
            />
          )}
        </>
      </Col>
      <Drawer
        title="Create New Asset"
        placement="right"
        destroyOnClose
        closable
        onClose={() => setAddNewAssetOpen(!addNewAssetOpen)}
        size="large"
        open={addNewAssetOpen}
        footer={
          <>
            <Button onClick={() => emptyFieldsOnCancel()} type="default">
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              form="createAssetClient"
              loading={createLoading}
              disabled={!isValid}
              style={{ marginLeft: '10px' }}
            >
              Create Asset
            </Button>
          </>
        }
      >
        {contextHolder}

        <form id="createAssetClient" onSubmit={handleSubmit(onCreateAsset)}>
          <Form.Item
            label={
              <span>
                <span>Customer ID</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="customer.id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  value={clientId!}
                  placeholder="Enter Customer ID"
                  aria-required="true"
                />
              )}
            />
            {errors.customer?.id?.type === 'required' && (
              <p role="alert">Customer is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Site ID</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="site.id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div className="search-field">
                  <Input
                    {...field}
                    placeholder="Enter Site Number"
                    aria-required="true"
                    onClick={() => setOpenSearchModal(true)}
                  />
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => setOpenSearchModal(true)}
                  >
                    Search
                  </Button>
                </div>
              )}
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Serial Number</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="serialNbr"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Serial Number"
                  aria-required="true"
                  type="number"
                />
              )}
            />
            {errors.serialNbr?.type === 'required' && (
              <p role="alert">Serial Number is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Product ID</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="productId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Product ID"
                  aria-required="true"
                  type="number"
                />
              )}
            />
            {errors.productId?.type === 'required' && (
              <p role="alert">Product ID is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Item Instance</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="itemInstance"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Item Instance"
                  aria-required="true"
                  type="number"
                />
              )}
            />
            {errors.itemInstance?.type === 'required' && (
              <p role="alert">Item Instance is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Software Key ID</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="softwareKeyId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Software Key ID"
                  aria-required="true"
                />
              )}
            />
            {errors.softwareKeyId?.type === 'required' && (
              <p role="alert">Software Key ID is required</p>
            )}
          </Form.Item>
          <Form.Item label="Asset Type">
            <Controller
              name="assetType"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Asset Type"
                  aria-required="true"
                  value="ATM"
                />
              )}
            />
          </Form.Item>
          <Form.Item label="Scan Latitude">
            <Controller
              name="scanLatitude"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Scan Latitude"
                  aria-required="true"
                />
              )}
            />
          </Form.Item>
          <Form.Item label="Scan Longitude">
            <Controller
              name="scanLongitude"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Scan Longitude"
                  aria-required="true"
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Retired</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="retired"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} placeholder="Select Retired">
                  <Select.Option value="true">True</Select.Option>
                  <Select.Option value="false">False</Select.Option>
                </Select>
              )}
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Active</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="active"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} placeholder="Select Active">
                  <Select.Option value="true">True</Select.Option>
                  <Select.Option value="false">False</Select.Option>
                </Select>
              )}
            />
            {errors.active?.type === 'required' && (
              <p role="alert">Active is required</p>
            )}
          </Form.Item>
        </form>
      </Drawer>
      <Modal
        title="Select Site"
        open={openSearchModal}
        onCancel={() => setOpenSearchModal(false)}
        width="80%"
        footer={[
          <Button
            key="back"
            size="large"
            onClick={() => setOpenSearchModal(false)}
          >
            Close
          </Button>,
        ]}
      >
        <ClientSitesTable
          tableAction={selectSiteForForm}
          siteSelector
          isCustomer
        />
      </Modal>
    </Row>
  );
};

export default ClientAssets;
