import { axiosPrivate, axiosMultipart } from '../api/axios';

export default class CSVService {
  static async uploadCSV(file: File, csvRoute: string) {
    const formData = new FormData();
    formData.append('file', file);
    return axiosMultipart({
      url: `/impex/import/${csvRoute}`,
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static async getUploadLogs(page: number, size: number, filters?: string) {
    return axiosPrivate({
      url: `/impex/import/logs?action=UPLOAD&${filters ? `${filters}` : ''}`,
      method: 'GET',
      params: {
        page,
        size,
      },
    });
  }

  static async getDownloadLogs(page: number, size: number, filters?: string) {
    return axiosPrivate({
      url: `/impex/import/logs?action=DOWNLOAD&${filters ? `${filters}` : ''}`,
      method: 'GET',
      params: {
        page,
        size,
      },
    });
  }

  static async downloadIngestedSourceData(jobId: string) {
    return axiosPrivate({
      url: `/impex/import/logs/${jobId}/srcfile`,
      method: 'GET',
    });
  }

  static async downloadExecutionSummary(jobId: string) {
    return axiosPrivate({
      url: `/impex/import/logs/${jobId}/summaryfile`,
      method: 'GET',
    });
  }

  static async processUploads() {
    return axiosPrivate({
      url: `/impex/import/process-import`,
      method: 'GET',
    });
  }
}
