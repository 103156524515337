import React from 'react';

import { Alert, Space, Spin } from 'antd';
import './Loader.scss';

interface LoaderProps {
  location?: string;
}

const Loader: React.FC<LoaderProps> = ({ location }) => {
  return (
    <div className={`loader ${location}`}>
      <Spin tip="Loading" size="large" className="spinner">
        <div className="content" />
      </Spin>
    </div>
  );
};

export default Loader;
