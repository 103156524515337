import React from 'react';

import './Footer.scss';
import Corda from '../../assets/images/corda.png';

const Footer = () => {
  return (
    <footer className=" shadow mt-auto">
      <div className="footer-container">
        <p className="text-l font-bold tracking-tight text-gray-50 ">
          © R3 2023
        </p>

        <p className="text-l font-bold tracking-tight text-gray-500">
          powered by
        </p>

        <img
          className="h-7 w-7 mr-4 -mt-3 ml-4"
          src={Corda}
          alt="Corda-Logo-Transparent"
        />
      </div>
    </footer>
  );
};

export default Footer;
