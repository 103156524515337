import React from 'react';

import ClientSitesTable from '../ClientSitesTable/ClientSitesTable';

const ClientSites = () => {
  const client = sessionStorage.getItem('customer');
  return <ClientSitesTable siteSelector={false} customerId={client!} />;
};

export default ClientSites;
