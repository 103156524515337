import React from 'react';

import { useLocation, Navigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

const RequireAuth = ({ allowedRoles, children }: any) => {
  const { auth } = useAuth();
  const location = useLocation();

  // eslint-disable-next-line no-nested-ternary
  return auth?.roles?.find((role: string) => allowedRoles?.includes(role)) ? (
    <>{children}</>
  ) : auth?.accessToken ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireAuth;
