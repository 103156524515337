/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useEffect, useState } from 'react';

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Tabs,
  notification,
  TablePaginationConfig,
  TabsProps,
  Tag,
  Tooltip,
  Spin,
  Skeleton,
} from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import './SingleContract.scss';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ContractService from '../../../APIServices/ContractService';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { AssetType } from '../../../models/AssetType';
import { ContractType } from '../../../models/ContractType';
import { ServiceType } from '../../../models/ServiceType';
import { convertDate } from '../../../utils/ConvertDate';
import ContractEntitlements from '../../ContractEntitlements/ContractEntitlements';
import ContractAssets from '../../NCRClient/ContractAssets/ContractAssets';
import NCRServicesTable from '../NCRServicesTable/NCRServicesTable';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const SingleContract = () => {
  // const [singleContract, setSingleContract] = useState<ContractType>()!;
  const contractID: string = window.location.pathname.split('/').pop()!;
  const [api, contextHolder] = notification.useNotification();

  const [current, setCurrent] = useState('');
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const onChange = (e: any) => {
    setCurrent(e.key);
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  const {
    data: singleContract,
    isLoading,
    refetch,
  } = useQuery(['singleContract', contractID], () =>
    axiosPrivate({
      method: 'GET',
      url: `/contracts/${contractID}`,
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        openNotificationWithIcon(
          'error',
          'Failed to get single contract',
          'Something  went wrong please try again later or contact support'
        );
        console.log(error);
      })
  );

  useEffect(() => {
    refetch();
  }, [contractID]);

  const items: TabsProps['items'] = [
    {
      label: 'Entitlements',
      key: 'services',
      children: (
        <ContractEntitlements contractServices={singleContract?.services} />
      ),
    },
    {
      label: 'Assets',
      key: 'assets',
      children: <ContractAssets assetData={singleContract?.assets!} />,
    },
  ];

  const viewSingleCustomer = (customer: string, contract: string) => {
    sessionStorage.setItem('customer', customer);
    navigate(`/ncr/customers/${customer}/contracts/${contract}`);
  };

  const contractStatus = (status: string) => {
    if (status === 'ACTIVE') {
      return <Tag color="green">Active</Tag>;
    }
    if (status === 'TERMINATED') {
      return <Tag color="default">Terminated</Tag>;
    }
    if (status === 'EXPIRED') {
      return <Tag color="red">Expired</Tag>;
    }
    if (status === 'HOLD') {
      return <Tag color="orange">Hold</Tag>;
    }
    if (status === 'QA_HOLD') {
      return <Tag color="yellow">Pending</Tag>;
    }

    return <Tag color="default">Other</Tag>;
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Row
      gutter={20}
      className="h-full"
      style={{ display: 'flex', flexWrap: 'wrap' }}
    >
      <Col xs={24} xl={4}>
        <Card id="ContractProps" title="Contract Information">
          {isLoading ? (
            <Skeleton />
          ) : (
            <ul className="AssetPropsList">
              <li>
                <p>
                  <span>Contract Status: </span>
                  {contractStatus(singleContract?.status!)}
                </p>
              </li>
              <li>
                <p>
                  <span>Contract Number: </span> {singleContract?.contractNbr}
                </p>
              </li>
              <li>
                <p>
                  <span>Contract Modifier: </span>{' '}
                  {singleContract?.contractModifier}
                </p>
              </li>
              <li>
                <p>
                  <span>Contract ID: </span> {singleContract?.ncrId}
                </p>
              </li>
              <li className="view-customer">
                <p>
                  <span>Customer Number: </span>
                  <Tooltip title={singleContract?.customer?.customerName}>
                    <Button
                      type="link"
                      size="small"
                      onClick={() =>
                        viewSingleCustomer(
                          singleContract?.customer?.id!,
                          singleContract?.id!
                        )
                      }
                    >
                      {singleContract?.customer?.customerNbr}
                    </Button>
                  </Tooltip>
                </p>
              </li>
              <li>
                <p>
                  <span>Category:</span> {singleContract?.category}
                </p>
              </li>
              <li>
                <p>
                  <span>Start Date: </span>
                  {convertDate(singleContract?.startDate)}
                </p>
              </li>
              <li>
                <p>
                  <span>End Date:</span> {}
                  {convertDate(singleContract?.endDate)}
                </p>
              </li>

              <li>
                <p>
                  <span>Last Updated:</span>{' '}
                  {convertDate(singleContract?.updatedAt)}
                </p>
              </li>
            </ul>
          )}
        </Card>
      </Col>
      <Col xs={24} xl={20}>
        <Tabs
          defaultActiveKey="1"
          id="singleClientContractTabs"
          className="custom-tabs"
          items={items}
          onChange={(e) => onChange}
        />
        <br />
      </Col>
    </Row>
  );
};

export default SingleContract;
