import React from 'react';

import { Col, Row } from 'antd';

import './NCRContracts.scss';

import NCRContractsTable from '../NCRContractsTable/NCRContractsTable';

const ClientContracts: React.FC = () => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <NCRContractsTable
          searchFields={[
            { id: 'contractModifier', value: 'Contract Modifier' },
            { id: 'status', value: 'Status' },
            { id: 'customerNbr', value: 'Customer Number' },
          ]}
        />
      </Col>
    </Row>
  );
};

export default ClientContracts;
