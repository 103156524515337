/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';

import { Button } from 'antd';
import L, { LatLngExpression, LatLngLiteral, LatLngTuple } from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useLocation, useNavigate } from 'react-router-dom';

import { blueIcon, greenIcon, redIcon } from './PinMarkerIcons';
import { useClient } from '../../Context/ClientContextProvider';
import { AssetType } from '../../models/AssetType';
import { samplePinDataObj } from '../../models/PinDataType';
import { SiteType } from '../../models/SiteType';
import Loader from '../Loader/Loader';
import './AssetMap.scss';

type Props = {
  redData?: AssetType[];
  blueData?: any;
  greenData?: AssetType[];
  siteData?: any[];
  height: number;
  isLoading?: boolean;
  currentSite?: any;
};

const AssetMap: React.FC<Props> = ({
  redData = [],
  greenData = [],
  blueData = [],
  siteData = [],
  height,
  isLoading,
  currentSite,
}) => {
  const navigate = useNavigate();
  const { singleClient } = useClient();
  const origLocation = useLocation().pathname;
  const customerID = singleClient?.id;

  const convertGeotagData = (geotagPoint: string[] = ['0', '0']) => {
    const latLon: LatLngTuple = [
      Number(geotagPoint[0] ? geotagPoint[0] : '0'),
      Number(geotagPoint[1] ? geotagPoint[1] : '0'),
    ];
    return latLon;
  };

  const convertToPinObject = (sites: any) => {
    const newAssetsList: samplePinDataObj[] = [];
    const assetsList: any[] = sites!;
    for (let i = 0; i < assetsList?.length; i += 1) {
      if (currentSite?.site?.ncrSiteId !== assetsList[i]?.site?.ncrSiteId) {
        newAssetsList.push({
          address: ''.concat(
            assetsList[i]?.site?.addressLine1!,
            ', ',
            assetsList[i]?.site?.city!,
            ', ',
            assetsList[i]?.site?.state!,
            ' ',
            assetsList[i]?.site?.postalCode!,
            ', ',
            assetsList[i]?.site?.country!
          ),

          id: assetsList[i]?.id!,
          coords: convertGeotagData(
            assetsList[i]?.site?.geotag
              ? assetsList[i]?.site?.geotag
              : ['0', '0']
          ),
          siteId: assetsList[i]?.site?.ncrSiteId!,
        });
        if (i === assetsList.length - 1) {
          newAssetsList.push({
            address: ''.concat(
              assetsList[i]?.previousSite?.addressLine1!,
              ', ',
              assetsList[i]?.previousSite?.city!,
              ', ',
              assetsList[i]?.previousSite?.state!,
              ' ',
              assetsList[i]?.previousSite?.postalCode!,
              ', ',
              assetsList[i]?.previousSite?.country!
            ),

            id: assetsList[i]?.previousSite?.id!,
            coords: convertGeotagData(
              assetsList[i]?.previousSite?.geotag
                ? assetsList[i]?.previousSite?.geotag
                : ['0', '0']
            ),
            siteId: assetsList[i]?.site?.ncrSiteId!,
          });
        }
      }
    }
    return newAssetsList;
  };

  const convertToPinObjectRed = (sites: any) => {
    const newAssetsList: samplePinDataObj[] = [];
    const assetsList: AssetType[] = sites!;

    for (let i = 0; i < assetsList?.length; i += 1) {
      newAssetsList.push({
        address: ''.concat(
          assetsList[i]?.site?.addressLine1!,
          ', ',
          assetsList[i]?.site?.city!,
          ', ',
          assetsList[i]?.site?.state!,
          ' ',
          assetsList[i]?.site?.postalCode!,
          ', ',
          assetsList[i]?.site?.country!
        ),
        id: assetsList[i]?.id!,
        coords: convertGeotagData(
          assetsList[i]?.site?.geotag ? assetsList[i]?.site?.geotag : ['0', '0']
        ),
        siteId: assetsList[i]?.site?.ncrSiteId!,
      });
      if (assetsList[i]?.locationMismatched === true) {
        newAssetsList.push({
          address: ''.concat(
            assetsList[i]?.site?.addressLine1!,
            ', ',
            assetsList[i]?.site?.city!,
            ', ',
            assetsList[i]?.site?.state!,
            ' ',
            assetsList[i]?.site?.postalCode!,
            ', ',
            assetsList[i]?.site?.country!
          ),

          id: assetsList[i]?.id!,
          coords: [
            Number(assetsList[i].scanLatitude!),
            Number(assetsList[i].scanLongitude!),
          ],
          siteId: assetsList[i]?.site?.ncrSiteId!,
        });
      }
    }
    return newAssetsList;
  };

  const convertSiteToPinObject = (sites: any[]) => {
    const newSitesList: samplePinDataObj[] = [];
    const sitesList: any[] = sites!;
    for (let i = 0; i < sitesList?.length; i += 1) {
      newSitesList.push({
        address: ''.concat(
          sitesList[i]?.addressLine1,
          ', ',
          sitesList[i]?.city,
          ', ',
          sitesList[i]?.state,
          ' ',
          sitesList[i]?.postalCode,
          ', ',
          sitesList[i]?.country
        ),
        id: sitesList[i]?.id,
        coords: convertGeotagData(
          sitesList[i]?.geotag ? sitesList[i]?.geotag : ['0', '0']
        ),
        siteId: sitesList[i]?.ncrSiteId!,
      });
    }
    return newSitesList;
  };

  const redDataPinObj = convertToPinObjectRed(redData);
  const blueDataPinObj = convertToPinObject(blueData);
  const greenDataPinObj = convertToPinObject(greenData);
  const siteDataPinObj = convertSiteToPinObject(siteData);

  const dataCenter = (data: samplePinDataObj[]) => {
    let sLat = 0;
    let sLong = 0;
    let cLat = 0;
    let cLong = 0;
    if (data.length !== 0) {
      for (let i = 0; i < data.length; i += 1) {
        sLat += data[i].coords[0];
        sLong += data[i].coords[1];
      }
      cLat = sLat / data.length;
      cLong = sLong / data.length;
    } else {
      cLat = 37.0902;
      cLong = 95.7129;
    }

    const coords: LatLngTuple = [cLat, cLong];
    return coords;
  };

  const dataZoom = (data: samplePinDataObj[]) => {
    const lats: number[] = [];
    const longs: number[] = [];
    let zoomLat = 0;
    let zoomLong = 0;
    let absDiffLat;
    let absDiffLong;
    if (data.length !== 0) {
      for (let i = 0; i < data.length; i += 1) {
        lats.push(data[i].coords[0]);
        longs.push(data[i].coords[1]);
      }
      absDiffLat = Math.abs(Math.max(...lats) - Math.min(...lats));
      absDiffLong = Math.abs(Math.max(...longs) - Math.min(...longs));
      zoomLat = Math.log2(360 / absDiffLat);
      zoomLong = Math.log2(720 / absDiffLong);
    }

    return Math.min(zoomLat, zoomLong);
  };
  const ChangeView = () => {
    const map = useMap();
    map.setView(
      dataCenter(
        redDataPinObj?.concat(blueDataPinObj, greenDataPinObj, siteDataPinObj)
      ),
      dataZoom(
        redDataPinObj?.concat(blueDataPinObj, greenDataPinObj, siteDataPinObj)
      )
    );
    return null;
  };
  const getCoords = (data: samplePinDataObj[]) => {
    const latlon: LatLngTuple[] = [];
    for (let i = 0; i < data.length; i += 1) {
      latlon.push(data[i].coords);
    }
    return latlon;
  };

  const getPath = () => {
    let location = origLocation;

    if (location.includes('/dashboard')) {
      location = '/dashboard';
    } else if (location.includes('/customers')) {
      location = `/ncr/customers/${customerID}`;
    } else {
      location = '/ncr';
    }
    return location;
  };

  return (
    <div className="asset-map">
      {redDataPinObj?.concat(blueDataPinObj, greenDataPinObj, siteDataPinObj)
        .length === 0 && (
        <div className="no-map-data-container">
          <div className="no-map-data">
            <h1>No Asset Data</h1>
          </div>
        </div>
      )}
      <MapContainer
        center={dataCenter(
          redDataPinObj?.concat(blueDataPinObj, greenDataPinObj, siteDataPinObj)
        )}
        zoom={dataZoom(
          redDataPinObj?.concat(blueDataPinObj, greenDataPinObj, siteDataPinObj)
        )}
        style={{ width: '100%', height }}
      >
        <ChangeView />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {getCoords(redDataPinObj).map((coord, index) => (
          <Marker
            // eslint-disable-next-line react/no-array-index-key
            key={`landmark-${index}`}
            position={coord}
            icon={redIcon}
            title={
              redData[index]?.site?.siteStatus === 'UnVerified'
                ? 'UNVERIFIED ADDRESS '.concat(
                    'Site Id: ',
                    redDataPinObj[index].siteId!,
                    '\n',
                    ' Address: ',
                    redDataPinObj[index].address!
                  )
                : redData[index]?.locationMismatched === true
                ? 'LOCATION MISMATCH'.concat(
                    'Site Id: ',
                    redDataPinObj[index].siteId!,
                    '\n',
                    ' Logged Address: ',
                    redDataPinObj[index].address!,
                    ' Logged Location: ',
                    redDataPinObj[index].coords!.toString()
                  )
                : 'LOCATION MISMATCH'.concat(
                    'Site Id: ',
                    redDataPinObj[index].siteId!,
                    '\n',
                    '; Current Location: ',
                    redDataPinObj[index].coords!.toString()
                  )
            }
            eventHandlers={{
              click: (e) => {
                navigate(
                  getPath().concat('/assets/', redDataPinObj[index].id!)
                );
              },
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                e.target.closePopup();
              },
            }}
          >
            <Popup>
              {redData[index]?.site?.siteStatus === 'UnVerified'
                ? 'UNVERIFIED ADDRESS '.concat(
                    'Site Id: ',
                    redDataPinObj[index].siteId!,
                    '\n',
                    ' Address: ',
                    redDataPinObj[index].address!
                  )
                : redData[index]?.locationMismatched === true
                ? 'LOCATION MISMATCH'.concat(
                    'Site Id: ',
                    redDataPinObj[index].siteId!,
                    '\n',
                    ' Logged Address: ',
                    redDataPinObj[index].address!,
                    ' Logged Location: ',
                    redDataPinObj[index].coords!.toString()
                  )
                : ''.concat(
                    'Site Id: ',
                    redDataPinObj[index].siteId!,
                    '\n',
                    '; Current Location: ',
                    redDataPinObj[index].coords!.toString()
                  )}
            </Popup>
          </Marker>
        ))}
        {getCoords(greenDataPinObj).map((coord, index) => (
          <Marker
            // eslint-disable-next-line react/no-array-index-key
            key={`landmark-${index}`}
            position={coord}
            icon={greenIcon}
            title={'Site Id: '.concat(
              greenDataPinObj[index].siteId!,
              '\n',
              'Address: ',
              greenDataPinObj[index].address!
            )}
            eventHandlers={{
              click: (e) => {
                navigate(
                  getPath().concat('/assets/', greenDataPinObj[index].id!)
                );
              },
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                e.target.closePopup();
              },
            }}
          >
            <Popup>
              {'Site Id: '.concat(
                greenDataPinObj[index].siteId!,
                '\n',
                'Address: ',
                greenDataPinObj[index].address!
              )}
            </Popup>
          </Marker>
        ))}
        {getCoords(blueDataPinObj).map((coord, index) => (
          <Marker
            // eslint-disable-next-line react/no-array-index-key
            key={`landmark-${index}`}
            position={coord}
            icon={blueIcon}
            title={'Site Id: '.concat(
              blueDataPinObj[index].siteId!,
              '\n',
              'Address: ',
              blueDataPinObj[index].address!
            )}
            eventHandlers={{
              click: (e) => {
                navigate(
                  getPath().concat('/assets/', blueDataPinObj[index].id!)
                );
              },
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                e.target.closePopup();
              },
            }}
          >
            <Popup>
              {'Site Id: '.concat(
                blueDataPinObj[index].siteId!,
                '\n',
                '\nAddress: ',
                blueDataPinObj[index].address!
              )}
            </Popup>
          </Marker>
        ))}
        {getCoords(siteDataPinObj).map((coord, index) => (
          <Marker
            // eslint-disable-next-line react/no-array-index-key
            key={`landmark-${index}`}
            position={coord}
            icon={
              siteData[index]?.siteStatus === 'Verified' ? greenIcon : redIcon
            }
            title={
              siteData[index]?.siteStatus === 'Verified'
                ? 'Site Id.: '.concat(
                    siteDataPinObj[index].siteId!,
                    '\n',
                    'Address: ',
                    siteDataPinObj[index].address!
                  )
                : siteData[index]?.locationMismatched === true
                ? 'LOCATION MISMATCH '.concat(
                    'Site Id: ',
                    siteDataPinObj[index].siteId!,
                    '\n',
                    ' Logged Address: ',
                    siteDataPinObj[index].address!,
                    '; Current Location: ',
                    siteDataPinObj[index].coords!.toString()
                  )
                : 'UNVERIFIED ADDRESS '.concat(
                    'Site Id: ',
                    siteDataPinObj[index].siteId!,
                    '\n',
                    ' Address: ',
                    siteDataPinObj[index].address!
                  )
            }
            eventHandlers={{
              click: (e) => {
                navigate(getPath().concat('/sites/', siteData[index].id!));
              },
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                e.target.closePopup();
              },
            }}
          >
            <Popup>
              {siteData[index]?.siteStatus === 'Verified'
                ? 'Site Id.: '.concat(
                    siteDataPinObj[index].siteId!,
                    '\n',
                    'Address: ',
                    siteDataPinObj[index].address!
                  )
                : siteData[index]?.locationMismatched === true
                ? 'LOCATION MISMATCH '.concat(
                    'Site Id: ',
                    siteDataPinObj[index].siteId!,
                    '\n',
                    ' Logged Address: ',
                    siteDataPinObj[index].address!,
                    '; Current Location: ',
                    siteDataPinObj[index].coords!.toString()
                  )
                : 'UNVERIFIED ADDRESS '.concat(
                    'Site Id: ',
                    siteDataPinObj[index].siteId!,
                    '\n',
                    ' Address: ',
                    siteDataPinObj[index].address!
                  )}
            </Popup>
          </Marker>
        ))}
        {currentSite?.locationVerificationStatus === 'Verified' && (
          <Marker
            // eslint-disable-next-line react/no-array-index-key
            key={`landmark-${currentSite?.site?.ncrSiteId}`}
            position={currentSite?.site?.geotag}
            icon={
              currentSite?.locationVerificationStatus === 'Verified'
                ? greenIcon
                : redIcon
            }
            title={
              currentSite?.locationVerificationStatus === 'Verified'
                ? 'Site Id.: '.concat(
                    currentSite?.site?.ncrSiteId!,
                    '\n',
                    'Address: ',
                    currentSite?.site?.address!
                  )
                : currentSite?.site?.locationMismatched === true
                ? 'LOCATION MISMATCH '.concat(
                    'Site Id: ',
                    currentSite?.site?.siteId!,
                    '\n',
                    ' Logged Address: ',
                    currentSite?.site?.address!,
                    '; Current Location: ',
                    currentSite?.site?.coords!.toString()
                  )
                : 'UNVERIFIED ADDRESS '.concat(
                    'Site Id: ',
                    currentSite?.site?.siteId!,
                    '\n',
                    ' Address: ',
                    currentSite?.site?.address!
                  )
            }
            eventHandlers={{
              click: (e) => {
                navigate(getPath().concat('/sites/', currentSite?.site?.id!));
              },
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                e.target.closePopup();
              },
            }}
          >
            <Popup>
              {currentSite?.site?.siteStatus === 'Verified'
                ? 'Site Id.: '.concat(
                    currentSite?.site?.siteId!,
                    '\n',
                    'Address: ',
                    currentSite?.site?.address!
                  )
                : currentSite?.site?.locationMismatched === true
                ? 'LOCATION MISMATCH '.concat(
                    'Site Id: ',
                    currentSite?.site?.siteId!,
                    '\n',
                    ' Logged Address: ',
                    currentSite?.site?.address!,
                    '; Current Location: ',
                    currentSite?.site?.coords!.toString()
                  )
                : 'UNVERIFIED ADDRESS '.concat(
                    'Site Id: ',
                    currentSite?.site?.siteId!,
                    '\n',
                    ' Address: ',
                    currentSite?.site?.address!
                  )}
            </Popup>
          </Marker>
        )}
      </MapContainer>
    </div>
  );
};

export default AssetMap;
