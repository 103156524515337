import { useState } from 'react';

import { notification } from 'antd';
import Cookies from 'js-cookie';
import jwt from 'jwt-decode';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import useAuth from './useAuth';
import useLogout from './useLogout';
import { axiosDefault } from '../api/axios';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const useRefreshToken = () => {
  const authData = useAuth();
  const { setAuth } = authData || {}; // Handle null authData
  const location = useLocation();
  const logout = useLogout();
  const refresh = async () => {
    try {
      const response = await axiosDefault({
        method: 'POST',
        url: '/refreshAccessToken',
        data: { refreshToken: sessionStorage.getItem('refreshToken') },
      });
      const accessToken = response?.data?.access_token;
      const refreshToken = response?.data?.refresh_token;
      const decodedUser = jwt(accessToken);
      const roles = (decodedUser as any)?.realm_access.roles;
      setAuth({
        accessToken,
        refreshToken,
        roles,
      });

      return response.data.access_token;
    } catch (error) {
      sessionStorage.setItem('lastLocation', location.pathname);
      logout();
      notification.open({
        message: 'Session Expired',
        key: 'token-expired',
        type: 'warning',
        duration: 10000,
        description: 'Your session has expired. Please login again.',
      });
    }
  };
  return refresh;
};

export default useRefreshToken;
