import React from 'react';

import { Card, Tabs, TabsProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import ContractAssetsTable from './ContractAssetsTable';
import { useClient } from '../../../Context/ClientContextProvider';
import AssetMap from '../../AssetMap/AssetMap';
import NCRAssetTable from '../../NCR/NCRAssetTable/NCRAssetTable';

interface ContractAssetsProps {
  assetData?: any;
  isCustomer?: boolean;
}

const ContractAssets: React.FC<ContractAssetsProps> = ({
  assetData,
  isCustomer,
}) => {
  const location = useLocation();
  const lastSegment = location.pathname.split('/')[3];

  return (
    <Card title="Assets">
      <ContractAssetsTable
        tableData={assetData}
        tableFilter={`contractId=${lastSegment}`}
        isCustomer={isCustomer}
        searchFields={[
          { id: 'status', value: 'Status' },
          { id: 'contractNbr', value: 'Contract Number' },
          { id: 'serialNbr', value: 'Serial Number' },
          { id: 'productId', value: 'Product ID' },
        ]}
      />

      <br />
    </Card>
  );
};

export default ContractAssets;
