import React, { createContext, useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { CustomerType } from '../models/CustomerType';

export type ClientContextType = {
  singleClient?: CustomerType | undefined;
  setSingleClient: (value: CustomerType | undefined) => void;
  redirect: () => void;
  getClientById: (value: any) => void;
};

export const ClientContext = createContext<ClientContextType | undefined>(
  undefined
);

export const ClientProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const id = sessionStorage.getItem('customer');
  const [singleClient, setSingleClient] = useState<CustomerType>();
  const location = useLocation();
  const token = sessionStorage.getItem('refreshToken');

  const axiosPrivate = useAxiosPrivate();

  const getClientById = async (value: any) => {
    axiosPrivate({
      method: 'GET',
      url: `/customers/${id}`,
    })
      .then((response) => {
        setSingleClient(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const redirect = () => {
    navigate('/');
  };

  useEffect(() => {
    if (location.pathname !== '/ncr' && token !== null) {
      getClientById(id!);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ClientContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ singleClient, setSingleClient, redirect, getClientById }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = () => {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error('useClient must be used within a Client Provider');
  }
  return context;
};
