import React, { useEffect, useState } from 'react';

import {
  Button,
  Row,
  Col,
  Card,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Empty,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ContractService from '../../../APIServices/ContractService';
import { ServiceType } from '../../../models/ServiceType';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface tableDataProps {
  tableData?: any;
}

const ServicesTable: React.FC<tableDataProps> = ({ tableData }) => {
  const columns: ColumnsType<ServiceType> = [
    {
      title: 'Entitlements Number',
      dataIndex: 'serviceNbr',
      key: 'serviceNbr',
    },
    {
      title: 'Entitlements Description',
      dataIndex: 'serviceDesc',
      key: 'serviceDesc',
    },
    {
      title: 'Entitlements Status',
      dataIndex: 'active',
      key: 'active',
      render: (text) => (
        <Tag color={text ? 'green' : 'red'}>{text ? 'Active' : 'Inactive'}</Tag>
      ),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title="Entitlements" className="h-full">
          <Table
            columns={columns}
            rowKey={(record) => record.id!}
            dataSource={tableData}
            pagination={false}
            locale={{
              emptyText: <Empty description="No entitlements" />,
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ServicesTable;
