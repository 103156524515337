import L from 'leaflet';

import bluePin from './pinImages/pin-blue.png';
import greenPin from './pinImages/pin-green.png';
import redPin from './pinImages/pin-red.png';
import yellowPin from './pinImages/pin-yellow.png';

export const greenIcon = new L.Icon({
  iconUrl: greenPin,
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [0, 0],
});
export const blueIcon = new L.Icon({
  iconUrl: bluePin,
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [0, 0],
});
export const redIcon = new L.Icon({
  iconUrl: redPin,
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [0, 0],
});
export const yellowIcon = new L.Icon({
  iconUrl: yellowPin,
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [0, 0],
});
