import React, { useState } from 'react';

import { UserOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Breadcrumb,
  Card,
  Avatar,
  Button,
  Input,
  RadioChangeEvent,
  Radio,
  Menu,
  MenuProps,
  Tabs,
  TabsProps,
} from 'antd';
import './NCRUserProfile.scss';
import Title from 'antd/lib/typography/Title';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import NCRUserProfile from './NCRUserProfile';
import NCRUserProfilePassword from './NCRUserProfilePassword';
import uploadIcon from '../../../assets/images/upload-icon.png';
import useLogout from '../../../hooks/useLogout';
import * as ROUTES from '../../../Routes/Routes';
import CSVManagement from '../../CSVManagement/CSVManagement';

const NCRUserProfileWrapper = () => {
  const [userView, setUserView] = useState<boolean>(true);
  const location = useLocation();
  const navigate = useNavigate();
  const logout = useLogout();

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  const items: TabsProps['items'] = [
    {
      label: 'User Profile',
      key: 'user-profile',
      children: <NCRUserProfile />,
    },
    {
      label: 'Password',
      key: 'user-password',
      children: <NCRUserProfilePassword />,
    },
  ];

  return (
    <>
      <Row gutter={16}>
        <Col span={4}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={ROUTES.DASHBOARD_NCR}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>My Account</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="client-header" id="clientNav">
          <h1 className="page-title">My Account</h1>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="1" tabPosition="left" items={items} />
        </Col>
      </Row>
    </>
  );
};

export default NCRUserProfileWrapper;
