/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  Col,
  Row,
  Modal,
  Form,
  Input,
  Radio,
  Select,
  Drawer,
  Tabs,
  TabsProps,
  notification,
  Tag,
  TablePaginationConfig,
  Tooltip,
  Spin,
  Skeleton,
} from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Controller, useForm } from 'react-hook-form';
import { Mutation, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import './SingleAsset.scss';
import AssetService from '../../../APIServices/AssetService';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useTagsWithColors } from '../../../hooks/useFlags';
import { ContractType } from '../../../models/ContractType';
import { ServiceType } from '../../../models/ServiceType';
import { convertDate } from '../../../utils/ConvertDate';
import statusUtil from '../../../utils/statusUtil';
import AssetActionHistory from '../../AssetActionHistory/AssetActionHistory';
import AssetActions from '../../AssetActions/AssetActions';
import AssetLocationHistory from '../../AssetLocationHistory/AssetLocationHistory';
import AssetEntitlements from '../../AsssetEntitlements/AssetEntitlements';
import Loader from '../../Loader/Loader';
import ClientSitesTable from '../../NCRClient/ClientSitesTable/ClientSitesTable';
import RetireAsset from '../../RetireAsset/RetireAsset';
import NCRAssetContractsTable from '../NCRAssetTable/NCRAssetContractsTable';

type NotificationType = 'success' | 'info' | 'warning' | 'error';
interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const SingleAsset = () => {
  const [current, setCurrent] = useState('asset-services');

  const [openRetireAssetModal, setOpenRetireAssetModal] = useState(false);
  const [openUpdateAssetModal, setOpenUpdateAssetModal] = useState(false);
  // const [pendingItems, setPendingItems] = useState<[]>([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const assetID: string = window.location.pathname.split('/').pop()!;
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [serviceData, setServiceData] = useState<ServiceType[]>();
  const [loadingServices, setLoadingServices] = useState(false);
  const [openContractsModal, setOpenContractsModal] = useState(false);
  const [assetContracts, setAssetContracts] = useState<ContractType[]>([]);
  const [updated, setUpdated] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState<string>();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitSuccessful, isValid },
  } = useForm();

  const emptyFieldsOnCancel = () => {
    reset();
    setOpenUpdateAssetModal(false);
  };
  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  const { data: singleAsset, isLoading } = useQuery(
    ['singleAsset', assetID],
    () =>
      axiosPrivate({
        method: 'GET',
        url: `/assets/${assetID}`,
      })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          openNotificationWithIcon(
            'error',
            'Failed to get single asset',
            'Something went wrong please try again later or contact support'
          );
          console.log(error);
        })
  );

  const tags = useTagsWithColors(singleAsset);

  const selectSiteForForm = (id: string) => {
    setSelectedSite(id);
    setOpenSearchModal(false);
    setValue('site.id', id, { shouldValidate: true });
  };

  const items: TabsProps['items'] = [
    {
      label: 'Entitlements',
      key: 'asset-services',
      children: <AssetEntitlements singleAsset={singleAsset!} />,
    },
    {
      label: 'Action Required',
      key: 'action-required',
      children: <AssetActions singleAsset={singleAsset} />,
    },
    // Commenting out Pending Tasks in Master
    // {
    //   label: 'Pending Tasks',
    //   key: 'pending-tasks',
    //   children: <AssetPendingTasks assetId={singleAsset?.id} />,
    // },

    {
      label: 'Asset History',
      key: 'asset-history',
      children: <AssetActionHistory singleAsset={singleAsset!} />,
    },
    {
      label: 'Location History',
      key: 'location-history',
      children: <AssetLocationHistory singleAsset={singleAsset!} />,
    },
  ];

  const onChange = (tabID: any) => {
    setCurrent(tabID);
  };

  const onUpdateAsset = async (data: any) => {
    try {
      const { data: response } = await AssetService.updateAsset(
        singleAsset.id,
        data
      );
      openNotificationWithIcon('success', 'Success', 'Asset update successful');
      queryClient.clear();
      return response;
    } catch (error) {
      openNotificationWithIcon('error', 'Failed', 'Asset update failed');
      throw error;
    }
  };

  const openViewContracts = (contractData: [ContractType]) => {
    setAssetContracts(contractData);
    setOpenContractsModal(true);
  };

  const contractsAction = (data: any) => {
    if (data?.length >= 1) {
      return (
        <Button
          type="link"
          size="small"
          onClick={() => openViewContracts(data!)}
        >
          View Contracts
        </Button>
      );
    }
    if (data === undefined) {
      <Tag color="volcano" key={1}>
        No Contract
      </Tag>;
    }
    return (
      <Tag color="volcano" key={1}>
        No Contract
      </Tag>
    );
  };

  const viewSingleCustomer = (customer: string, asset: string) => {
    sessionStorage.setItem('customer', customer);
    navigate(`/ncr/customers/${customer}/assets/${asset}`);
  };

  return (
    <Row gutter={20} className="h-full">
      {contextHolder}
      <Col xs={24} xl={24}>
        <div className="asset-cta-toolbar">
          <div className="asset-action-buttons">
            <div className="asset-actions">
              <Button
                className="updateAssetButton"
                type="primary"
                size="large"
                onClick={() => setOpenUpdateAssetModal(true)}
                hidden
              >
                Update Asset
              </Button>
              <Button
                className="updateAssetButton"
                type="primary"
                size="large"
                onClick={() => setOpenRetireAssetModal(true)}
                hidden
              >
                Retire Asset
              </Button>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={24} xl={5}>
        <Card title="Asset Information">
          {isLoading ? (
            <Skeleton loading={isLoading} />
          ) : (
            <ul className="AssetPropsList">
              <li>
                <p>
                  <span>Last Updated: </span>
                  {convertDate(singleAsset?.updatedAt)}
                </p>
              </li>
              <li>
                <p>
                  <span>Asset Status: </span>
                  {statusUtil(singleAsset?.status!)}
                </p>
              </li>
              {tags.length > 0 && (
                <li className="flag-tags">
                  <p>
                    <span>Asset Flags: </span>
                    {tags.map((tag) => (
                      <Tag color={tag.color} key={tag.tag}>
                        {tag.text}
                      </Tag>
                    ))}
                  </p>
                </li>
              )}
              <li>
                <p>
                  <span>Contracts: </span>
                  {singleAsset?.requireContract === true ? (
                    <Tag color="volcano" key={1}>
                      Require New Contract
                    </Tag>
                  ) : (
                    contractsAction(singleAsset?.contracts)
                  )}
                </p>
              </li>
              {singleAsset?.serviceTag !== '' && (
                <li>
                  <p>
                    <span>Service Tag: </span>
                    {singleAsset?.serviceTag}
                  </p>
                </li>
              )}
              <li className="view-customer">
                <p>
                  <span>Customer Number: </span>
                  {singleAsset?.customer !== undefined ? (
                    <Tooltip title={singleAsset?.customer?.customerName}>
                      <Button
                        type="link"
                        size="small"
                        onClick={() =>
                          viewSingleCustomer(
                            singleAsset?.customer?.id!,
                            singleAsset?.id!
                          )
                        }
                      >
                        {singleAsset?.customer?.customerNbr}
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tag color="volcano" key={1}>
                      No Customer
                    </Tag>
                  )}
                </p>
              </li>

              <li>
                <p>
                  <span>Asset Description: </span>
                  {singleAsset?.assetDescription}
                </p>
              </li>
              <li>
                <p>
                  <span>NCR Site ID: </span>
                  {singleAsset?.site?.ncrSiteId !== undefined ? (
                    singleAsset?.site?.ncrSiteId
                  ) : (
                    <Tag color="volcano">No Site ID</Tag>
                  )}
                </p>
              </li>
              <li>
                <p>
                  <span>Site Name: </span>
                  {singleAsset?.site?.name !== undefined ? (
                    singleAsset?.site?.name
                  ) : (
                    <Tag color="volcano">No Site Name</Tag>
                  )}
                </p>
              </li>

              <li>
                <p>
                  <span>Item Instance: </span>
                  {singleAsset?.itemInstance}
                </p>
              </li>
              <li>
                <p>
                  <span>Product ID: </span>
                  {singleAsset?.productId}
                </p>
              </li>
              <li>
                <p>
                  <span>Product Class: </span>
                  {singleAsset?.assetClass}
                </p>
              </li>
              <li>
                <p>
                  <span>Serial Number: </span>
                  {singleAsset?.serialNbr}
                </p>
              </li>
              <li>
                <p>
                  <span>Software Key ID: </span>
                  {singleAsset?.softwareKeyId}
                </p>
              </li>
              <li>
                <p>
                  <span>Location: </span>
                  {singleAsset?.site?.name}
                </p>
              </li>
              {/* <li>
                <p>
                  <span>Retired: </span>
                  {singleAsset?.retired ? 'True' : 'False'}
                </p>
              </li> */}
            </ul>
          )}
        </Card>
      </Col>
      <Col xs={24} xl={19}>
        {isLoading ? (
          <Skeleton loading={isLoading} />
        ) : (
          <Tabs
            defaultActiveKey="action-required"
            className="custom-tabs"
            onTabClick={(key) => onChange(key)}
            items={items}
            activeKey={current}
          />
        )}
        <br />
      </Col>

      <RetireAsset
        openRetireAssetModal={openRetireAssetModal}
        singleAsset={singleAsset}
        // pendingItems={pendingItems}
        setOpenRetireAssetModal={setOpenRetireAssetModal}
      />
      <Drawer
        title="Update Asset"
        open={openUpdateAssetModal}
        placement="right"
        size="large"
        closable
        onClose={() => setOpenUpdateAssetModal(false)}
        footer={[
          <Button key="back" size="large" onClick={() => emptyFieldsOnCancel()}>
            Cancel
          </Button>,
          <Button
            style={{ marginLeft: '10px' }}
            key="submit"
            type="primary"
            size="large"
            htmlType="submit"
            disabled={!isValid}
            form="updateAsset"
          >
            Update Asset
          </Button>,
        ]}
      >
        <form id="updateAsset" onSubmit={handleSubmit(onUpdateAsset)}>
          <Form.Item
            label={
              <span>
                <span>Item Instance</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="itemInstance"
              control={control}
              defaultValue={singleAsset?.itemInstance}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter item instance"
                  aria-required="true"
                />
              )}
            />
            {errors.itemInstance?.type === 'required' && (
              <p role="alert">Item Instance is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Asset Type</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="assetType"
              control={control}
              defaultValue={singleAsset?.assetType}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} placeholder="Select Asset Type">
                  <Select.Option value="ATM">ATM</Select.Option>
                </Select>
              )}
            />
            {errors.assetType?.type === 'required' && (
              <p role="alert">Asset Type is required</p>
            )}
          </Form.Item>

          <Form.Item
            label={
              <span>
                <span>Software Key ID</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="softwareKeyId"
              control={control}
              defaultValue={singleAsset?.softwareKeyId}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Software Key ID"
                  aria-required="true"
                />
              )}
            />
            {errors.softwareKeyId?.type === 'required' && (
              <p role="alert">Software Key ID is required</p>
            )}
          </Form.Item>
          <Form.Item label="Retired" hidden>
            <Input
              value={singleAsset?.retired === false ? 'false' : 'true'}
              hidden
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Product ID</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="productId"
              control={control}
              defaultValue={singleAsset?.productId}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled
                  placeholder="Enter Product ID"
                  aria-required="true"
                />
              )}
            />
            {errors.productId?.type === 'required' && (
              <p role="alert">Product ID is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Serial Number</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="serialNbr"
              control={control}
              defaultValue={singleAsset?.serialNbr}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled
                  placeholder="Enter Serial Number"
                  aria-required="true"
                />
              )}
            />
            {errors.serialNbr?.type === 'required' && (
              <p role="alert">Serial Number is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Status</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="status"
              defaultValue={singleAsset?.status}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input {...field} disabled placeholder="Enter Status" />
              )}
            />
            {errors.status?.type === 'required' && (
              <p role="alert">Status is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Asset Active</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="active"
              defaultValue={singleAsset?.active!.toString()}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} placeholder="Select Active">
                  <Select.Option value="true">True</Select.Option>
                  <Select.Option value="false">False</Select.Option>
                </Select>
              )}
            />
            {errors.active?.type === 'required' && (
              <p role="alert">Active is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Site ID</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="site.id"
              control={control}
              defaultValue={singleAsset?.site?.id}
              rules={{ required: true }}
              render={({ field }) => (
                <div className="search-field">
                  <Input
                    {...field}
                    placeholder="Enter Site Number"
                    aria-required="true"
                    onClick={() => setOpenSearchModal(true)}
                  />
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => setOpenSearchModal(true)}
                  >
                    Search
                  </Button>
                </div>
              )}
            />
            {errors.site?.type === 'required' && (
              <p role="alert">Site is required</p>
            )}
          </Form.Item>
        </form>
      </Drawer>
      <Modal
        title="Select Site "
        open={openSearchModal}
        onCancel={() => setOpenSearchModal(false)}
        width="80%"
        footer={[
          <Button
            key="back"
            size="large"
            onClick={() => setOpenSearchModal(false)}
          >
            Close
          </Button>,
        ]}
      >
        <ClientSitesTable
          tableAction={selectSiteForForm}
          siteSelector
          customerId={singleAsset?.customer?.id!}
          isCustomer
        />
      </Modal>
      <Modal
        title="Asset Contracts"
        open={openContractsModal}
        onCancel={() => setOpenContractsModal(false)}
        width="80%"
        footer={[
          <Button
            key="back"
            size="large"
            onClick={() => setOpenContractsModal(false)}
          >
            Close
          </Button>,
        ]}
      >
        <NCRAssetContractsTable
          tableData={assetContracts}
          searchFields={[
            { id: 'status', value: 'Status' },
            { id: 'contractNbr', value: 'Contract Number' },
          ]}
        />
      </Modal>
    </Row>
  );
};

export default SingleAsset;
