import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const element = document.getElementById('clientNav')!;
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({});
    }
  }, [pathname]);

  return null;
};
