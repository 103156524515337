import React, { useRef, useState } from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Row, Col, Card, Button } from 'antd';
import './ClientUserProfile.scss';
import Title from 'antd/lib/typography/Title';
import { Link } from 'react-router-dom';

import { axiosPrivate, BASE_URL } from '../../../api/axios';
import uploadIcon from '../../../assets/images/upload-icon.png';
import * as ROUTES from '../../../Routes/Routes';

const ClientUserProfilePassword = () => {
  const userData = sessionStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;
  const appUrl = encodeURIComponent(window.location.toString());
  const clientID = 'ncr-client';

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Card
          title={
            <Title
              style={{
                fontSize: 'xx-large',
                color: '#5a5a5a',
                marginTop: '25px',
                marginLeft: '15px',
              }}
            >
              Update Password
              <p
                style={{
                  fontSize: 'small',
                  color: '#777777',
                  marginTop: '10px',
                }}
              >
                Navigate to Keycloak to update your Account Password here:
              </p>
            </Title>
          }
          id="UpdateProps"
        >
          <Link
            to={`${process.env
              .REACT_APP_KEYCLOAK_URL!}/realms/ncr/protocol/openid-connect/auth?response_type=code&client_id=${clientID}&redirect_uri=${appUrl}&kc_action=UPDATE_PASSWORD`}
          >
            <Button type="primary" size="large" style={{ marginLeft: '15px' }}>
              Update Password
            </Button>
          </Link>
        </Card>
      </Col>
    </Row>
  );
};

export default ClientUserProfilePassword;
