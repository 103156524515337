import { type } from 'os';

import React, { useState, useEffect } from 'react';

import { ClockCircleOutlined, FormOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  Collapse,
  Empty,
  List,
  Row,
  Table,
  TablePaginationConfig,
  Tag,
  Timeline,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FilterValue } from 'antd/lib/table/interface';
import { useQuery } from 'react-query';

import AssetService from '../../APIServices/AssetService';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { AssetMoveHistoryType } from '../../models/AssetMoveHistoryType';
import { AssetType } from '../../models/AssetType';
import { CustomerType } from '../../models/CustomerType';
import { AssetHistoryType } from '../../models/HistoryAsset';
import { SiteType } from '../../models/SiteType';
import { convertDate } from '../../utils/ConvertDate';

interface AssetActionHistoryProps {
  singleAsset?: any;
  tab?: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface tableDataProps {
  currentTableData: AssetType;
  pastTableData: AssetMoveHistoryType[];
  columnParams?: ColumnsType<SiteType>;
}

type ActionType = {
  actionOwner: string;
  customer: CustomerType;
  historyStateId: string;
  retired: boolean;
  services: string[];
  site: SiteType;
  softwareKeyId: string;
  status: string;
  updatedAt: string;
  locationUpdated: boolean;
  contractUpdated: boolean;
  ownerUpdated: boolean;
  serviceUpdated: boolean;
};

const { Panel } = Collapse;

const AssetActionHistory: React.FC<AssetActionHistoryProps> = ({
  singleAsset,
  tab,
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const { data: assetHistory, isLoading } = useQuery(
    ['assetHistory', page, pageSize, singleAsset?.id],
    () => AssetService.getAssetHistory(singleAsset.id!, page, pageSize)
  );

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const historyStatus = (status: string) => {
    if (status === 'Initialized') {
      return (
        <Timeline.Item>
          <strong>Status:</strong> <Tag color="green"> Asset Initialized</Tag>
        </Timeline.Item>
      );
    }
    // if (status === 'PendingApproval') {
    //   return (
    //     <Timeline.Item>
    //       <strong>Status:</strong> <Tag color="yellow">Pending Approval</Tag>
    //     </Timeline.Item>
    //   );
    // }
    if (status === 'Rejected') {
      return (
        <Timeline.Item>
          <strong>Status:</strong> <Tag color="volcano">Rejected</Tag>
        </Timeline.Item>
      );
    }
    if (status === 'Updated') {
      return (
        <Timeline.Item>
          <strong>Status:</strong> <Tag color="blue">Updated</Tag>
        </Timeline.Item>
      );
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Card title="Asset History">
          {assetHistory?.data.length >= 1 ? (
            <List
              className="added-services"
              loading={isLoading}
              pagination={{
                defaultPageSize: 5,
                total: Number(assetHistory?.headers['x-total-count']),
                pageSizeOptions: ['5', '10', '25', '50'],
                showSizeChanger: true,
                onChange: (pageList: any, pageSizeList: any) => {
                  setPage(pageList - 1);
                  setPageSize(pageSizeList);
                },
                hideOnSinglePage:
                  Number(assetHistory?.headers['x-total-count']) <= pageSize,
              }}
            >
              {assetHistory?.data.map((historyItem: AssetHistoryType) => (
                <Timeline
                  className="status-list snaps"
                  mode="alternate"
                  key={historyItem.historyStateId}
                >
                  {historyStatus(historyItem.status)}
                  {/* {historyItem.atDestination && (
                              <Timeline.Item>
                                <strong>At Destination:</strong>{' '}
                                <Tag color="green">Yes</Tag>
                              </Timeline.Item>
                            )} */}

                  {historyItem.contractUpdated && (
                    <Timeline.Item>
                      <strong>Contract Updated: </strong>{' '}
                      <Tag color="green">Yes</Tag>
                    </Timeline.Item>
                  )}
                  {historyItem.locationUpdated && (
                    <Timeline.Item>
                      <span>
                        <strong>Location Updated: </strong>{' '}
                        <Tag color="green">Yes</Tag>
                        {!historyItem.autoUpdated ? (
                          <Tag color="blue">Auto Updated</Tag>
                        ) : (
                          <Tag color="purple">Manual</Tag>
                        )}
                      </span>
                      {historyItem?.site?.ncrSiteId !== undefined ? (
                        <p>
                          {' '}
                          <span>
                            <strong>NCR Id: </strong>
                            {historyItem?.site?.ncrSiteId}
                          </span>
                          <br />
                        </p>
                      ) : (
                        <p>
                          {' '}
                          <span>
                            <strong>NCR: </strong>
                            No Location Information
                          </span>
                          <br />
                        </p>
                      )}
                    </Timeline.Item>
                  )}
                  {historyItem.ownerUpdated && (
                    <Timeline.Item>
                      <strong>Owner Updated:</strong>{' '}
                      <Tag color="green">Yes</Tag>
                      <p>
                        {' '}
                        <span>
                          <strong>Customer Nbr: </strong>
                          {historyItem.customer?.customerNbr}
                        </span>
                      </p>
                    </Timeline.Item>
                  )}

                  {historyItem.serviceUpdated && (
                    <Timeline.Item>
                      <span>
                        <strong>Entitlement Updated: </strong>{' '}
                        <Tag color="green">Yes</Tag>
                        {historyItem.autoUpdated ? (
                          <Tag color="blue">Auto Updated</Tag>
                        ) : (
                          <Tag color="purple">Manual</Tag>
                        )}
                      </span>
                      <br />
                      <br />
                      <Collapse
                        onChange={onChange}
                        className="service-panel"
                        key={historyItem.historyStateId + historyItem.updatedAt}
                      >
                        <Panel
                          header="Service Updates"
                          key={historyItem.actionOwner.commonName}
                        >
                          <div className="service-history-lists">
                            {historyItem.addedServices.length !== 0 && (
                              <div className="service-list">
                                <List
                                  header={<div>Added</div>}
                                  className="added-services"
                                  bordered
                                  dataSource={historyItem.addedServices}
                                  renderItem={(item: any) => (
                                    <List.Item>{item}</List.Item>
                                  )}
                                />
                              </div>
                            )}
                            {historyItem.removedServices.length !== 0 && (
                              <div className="service-list">
                                <List
                                  header={<div>Removed</div>}
                                  className="removed-services"
                                  bordered
                                  dataSource={historyItem.removedServices}
                                  renderItem={(item: any) => (
                                    <List.Item>{item}</List.Item>
                                  )}
                                />
                              </div>
                            )}
                            {historyItem.services.length !== 0 && (
                              <div className="service-list">
                                <List
                                  header={<div>Active</div>}
                                  className="current-services"
                                  bordered
                                  dataSource={historyItem.services}
                                  renderItem={(item: any) => (
                                    <List.Item>{item}</List.Item>
                                  )}
                                />
                              </div>
                            )}
                          </div>
                        </Panel>
                      </Collapse>
                    </Timeline.Item>
                  )}
                  {historyItem.retired && (
                    <Timeline.Item>
                      <strong>Retired:</strong> <Tag color="green">Yes</Tag>
                    </Timeline.Item>
                  )}
                  <Timeline.Item
                    color="#000000d9"
                    dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}
                  >
                    <strong>Updated By:</strong>{' '}
                    <Tag color="default">{historyItem.evolvedBy}</Tag>
                    <strong>Date:</strong>{' '}
                    <Tag color="default">
                      {convertDate(historyItem.updatedAt)} -{' '}
                    </Tag>
                  </Timeline.Item>
                </Timeline>
              ))}
            </List>
          ) : (
            <Empty description="No history associated with this asset" />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default AssetActionHistory;
