import React from 'react';

import { Tag } from 'antd';

const setStatusStyle = (status: string) => {
  switch (status) {
    case 'Active':
      return <Tag color="green">{status}</Tag>;
    case 'Updated':
      return <Tag color="blue">{status}</Tag>;
    case 'PendingApproval':
      return <Tag color="blue">Pending Approval</Tag>;
    case 'Initialized' || 'Initialised':
      return <Tag color="blue">{status}</Tag>;
    default:
      return '';
  }
};

export default setStatusStyle;
