/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useEffect, useState } from 'react';

import {
  DollarCircleOutlined,
  RightSquareOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  AreaChartOutlined,
  FileExclamationOutlined,
  RedoOutlined,
} from '@ant-design/icons/lib/icons';
import { Card, Tag } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import './ClientHome.scss';

import { useClient } from '../../../Context/ClientContextProvider';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { CustomerType } from '../../../models/CustomerType';
import ClientAssetTable from '../ClientAssetTable/ClientAssetTable';

const ClientHome = () => {
  const { singleClient } = useClient();
  const { auth } = useAuth();
  const [assetPending, setAssetPending] = useState('0');
  const [tableFilter, setTableFilter] = useState('actionRequired=true');
  const navigate = useNavigate();
  const location = useLocation();
  const [tableTitle, setTableTitle] = useState('Action Required');
  const [customerData, setCustomerData] = useState<CustomerType>();
  const lastSegment = location.pathname.split('/').pop();
  const axiosPrivate = useAxiosPrivate();
  const id = sessionStorage.getItem('customer');

  const getCustomerById = async () => {
    axiosPrivate({
      method: 'GET',
      url: `customers/${id}`,
    })
      .then((response: any) => {
        setCustomerData(response.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCustomerById();
  }, []);

  const fetchAssetByStatus = async (status: string) => {
    axiosPrivate({
      method: 'GET',
      url: `assets?customerId=${id}&${status}`,
    })
      .then((response: any) => {
        if (status === 'status=PendingApproval') {
          setAssetPending(response.headers['x-total-count']);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAssetByStatus('status=PendingApproval');
  }, []);

  const getSingleAssetView = (assetID: string) => {
    if (lastSegment === 'overview') {
      navigate(`/ncr/customers/${id}/assets/${assetID}`);
    } else {
      navigate(`assets/${assetID}`);
    }
  };

  const setTableFilterByStatus = (status: { value: string; name: string }) => {
    setTableFilter(status.name);
    setTableTitle(status.value);
  };

  return (
    <>
      <div className="dashboard-tiles">
        <Card
          title="Auto Updates"
          className="total-card"
          onClick={() =>
            setTableFilterByStatus({
              value: 'Auto Updates',
              name: 'autoUpdated=true',
            })
          }
          extra={
            <Tag color="blue" className="custom-tag">
              <RedoOutlined />
            </Tag>
          }
        >
          <p className="status-title">
            {customerData?.autoUpdatedAssetCount === undefined
              ? 0
              : customerData?.autoUpdatedAssetCount}
          </p>
        </Card>
        <Card
          title="Require New Contract"
          className="leaks-card"
          onClick={() =>
            setTableFilterByStatus({
              value: 'Require New Contract',
              name: 'requireContract=true',
            })
          }
          extra={
            <Tag color="red" className="custom-tag">
              <FileExclamationOutlined />
            </Tag>
          }
        >
          <p className="status-title">
            {customerData?.requireContractAssetCount === undefined
              ? 0
              : customerData?.requireContractAssetCount}
          </p>
        </Card>

        <Card
          title="Auto Moved Assets"
          className="moved-card"
          onClick={() =>
            setTableFilterByStatus({
              value: 'Auto Moved Assets',
              name: 'autoMoved=true',
            })
          }
          extra={
            <Tag color="green" className="custom-tag">
              <RightSquareOutlined />
            </Tag>
          }
        >
          <p className="status-title">
            {customerData?.autoMovedAssetCount === undefined
              ? 0
              : customerData?.autoMovedAssetCount}
          </p>
        </Card>
        <Card
          title="Action Required"
          className="leaks-card"
          onClick={() =>
            setTableFilterByStatus({
              value: 'Action Required Assets',
              name: 'actionRequired=true',
            })
          }
          extra={
            <Tag color="volcano" className="custom-tag">
              <ExclamationCircleOutlined />
            </Tag>
          }
        >
          <p className="status-title">
            {customerData?.actionRequiredAssetCount === undefined
              ? 0
              : customerData?.actionRequiredAssetCount}
          </p>
        </Card>

        {/* <Card */}
        {/*  title="Assets Pending Approval" */}
        {/*  onClick={() => */}
        {/*    setTableFilterByStatus({ */}
        {/*      value: 'Assets Pending Approval', */}
        {/*      name: 'status=PendingApproval', */}
        {/*    }) */}
        {/*  } */}
        {/*  className="pending-card" */}
        {/*  extra={ */}
        {/*    <Tag color="yellow" className="custom-tag"> */}
        {/*      <FileDoneOutlined /> */}
        {/*    </Tag> */}
        {/*  } */}
        {/* > */}
        {/*  <p className="status-title">{assetPending}</p> */}
        {/* </Card> */}

        <Card
          title="Total Assets"
          onClick={() =>
            setTableFilterByStatus({
              value: 'Assets',
              name: '',
            })
          }
          className="total-card"
          extra={
            <Tag color="default" className="custom-tag">
              <AreaChartOutlined />
            </Tag>
          }
        >
          <p className="status-title">{singleClient?.assetCount}</p>
        </Card>
      </div>
      <br />
      <ClientAssetTable
        tableTitle={tableTitle}
        tableAction={getSingleAssetView}
        isCustomer
        tableFilter={tableFilter}
      />
    </>
  );
};

export default ClientHome;
