import React from 'react';

import ReactDOM from 'react-dom';

import App from './App';
import { AuthProvider } from './auth/AuthProvider';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.less';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore next-line */}
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
