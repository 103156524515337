import { axiosPrivate } from '../api/axios';
import { CustomerType } from '../models/CustomerType';

export default class CustomerService {
  static async getCustomers(page: number, size: number, filters?: string) {
    return axiosPrivate({
      url: `/customers${filters ? `?${filters}` : ''}`,
      method: 'GET',
      params: {
        page,
        size,
      },
    });
  }

  static async getCustomerById(id: string) {
    return axiosPrivate({
      url: `/customers/${id}`,
      method: 'GET',
    });
  }

  static async createCustomer(customer: CustomerType) {
    return axiosPrivate({
      url: `/customers`,
      method: 'POST',
      data: customer,
    });
  }

  static async updateCustomer(customer: CustomerType) {
    return axiosPrivate({
      url: `/customers/${customer.id}`,
      method: 'PUT',
      data: customer,
    });
  }

  static async deleteCustomer(id: string) {
    return axiosPrivate({
      url: `/customers/${id}`,
      method: 'DELETE',
    });
  }
}
