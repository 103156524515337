/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';

import { Button, Result, Row, Col, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import * as ROUTES from '../../Routes/Routes';
import MainHeader from '../Header/Header';

import './NotFoundPage.style.scss';

const NotFoundPage: React.FC = () => {
  const { auth } = useAuth();
  const [isLoggedIn, setIsloggedIn] = useState(false);
  const { Header, Content } = Layout;
  const user = sessionStorage.getItem('user');
  useEffect(() => {
    if (user !== undefined) {
      setIsloggedIn(true);
    }
  }, [user]);

  const navigate = useNavigate();
  return (
    <div className="site-layout">
      <Header>
        <Row>
          <Col span={24}>
            <MainHeader />
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={
                !isLoggedIn ? (
                  <>
                    <Button
                      onClick={() => navigate('/')}
                      type="primary"
                      size="large"
                    >
                      Go to Login
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() =>
                      navigate(
                        auth?.roles.includes('ncr-admin')
                          ? ROUTES.DASHBOARD_NCR
                          : ROUTES.DASHBOARD_NCR_CLIENT
                      )
                    }
                    type="primary"
                    size="small"
                  >
                    Back to Home
                  </Button>
                )
              }
            />
          </Col>
        </Row>
      </Header>
    </div>
  );
};

export default NotFoundPage;
