/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';

import { CloseCircleOutlined } from '@ant-design/icons/lib/icons';
import {
  Button,
  Row,
  Col,
  Card,
  Table,
  TablePaginationConfig,
  Tag,
  Select,
  Input,
  Empty,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { FilterValue } from 'antd/lib/table/interface';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ContractService from '../../../APIServices/ContractService';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { ContractType } from '../../../models/ContractType';
import { CustomerType } from '../../../models/CustomerType';
import { convertDate } from '../../../utils/ConvertDate';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface tableDataProps {
  tableData?: ContractType[];
  searchFields?: { id: string; value: string }[];
  isCustomer?: boolean;
}

const ClientContractsTable: React.FC<tableDataProps> = ({
  tableData,
  searchFields,
  isCustomer,
}) => {
  const [searchField, setSearchField] = useState('Select Field');
  const [searchFieldSub, setSearchFieldSub] = useState('Select Field');
  const [selectSearchTerms, setSelectSearchTerms] = useState<string>('');
  const { Search } = Input;
  const { Option } = Select;
  const clientID = sessionStorage.getItem('customer');
  const [disableSearch, setDisableSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { auth } = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [dropdownItems, setDropdownItems] = useState([
    { id: 'ACTIVE', value: 'Active' },
    { id: 'EXPIRED', value: 'Expired' },
    { id: 'TERMINATED', value: 'Terminated' },
    { id: 'HOLD', value: 'Hold' },
  ]);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [filters, setFilters] = useState('');

  const getSingleContractView = (contractID: string, customerID: string) => {
    if (isCustomer && auth.roles.includes('ncr-admin')) {
      navigate(`/ncr/customers/${clientID}/contracts/${contractID}`);
    } else if (auth.roles.includes('ncr-admin')) {
      navigate(`/ncr/contracts/${contractID}`);
    } else if (auth.roles.includes('ncr-client')) {
      navigate(`/dashboard/contracts/${contractID}`);
    }
  };

  const columns: ColumnsType<ContractType> = [
    {
      title: 'Contract Number',
      dataIndex: 'contractNbr',
      key: 'contractNbr',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Contract Modifier',
      dataIndex: 'contractModifier',
      key: 'contractModifier',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Auto Updates',
      dataIndex: 'autoUpdated',
      key: 'autoUpdated',
      render: (_, record) => {
        const color = 'green';
        const text = 'No Updates';
        if (record.autoUpdated) {
          return (
            <Tag
              color="blue"
              key={record.id}
              className="asset-approval-link"
              onClick={() => getSingleContractView(record.id!, clientID!)}
            >
              Auto Updates
            </Tag>
          );
        }

        return (
          <Tag color={color} key={record.id}>
            {text}
          </Tag>
        );
      },
    },

    {
      title: 'Asset Updated',
      dataIndex: 'assetUpdated',
      key: 'assetUpdated',
      render: (_, record) => {
        const color = 'green';
        const text = 'No Updates';
        if (record.autoUpdated) {
          return (
            <Tag color="blue" key={record.id}>
              Asset Updates
            </Tag>
          );
        }

        return (
          <Tag color={color} key={record.id}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: 'Service Updated',
      dataIndex: 'serviceUpdated',
      key: 'serviceUpdated',
      render: (_, record) => {
        const color = 'green';
        const text = 'No Updates';
        if (record.autoUpdated) {
          return (
            <Tag color="blue" key={record.id}>
              Service Updates
            </Tag>
          );
        }

        return (
          <Tag color={color} key={record.id}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, { status }) => {
        let color = 'green';
        text = 'Active';
        if (status === 'EXPIRED') {
          color = 'volcano';
          text = 'Expired';
        }
        if (status === 'HOLD') {
          color = 'yellow';
          text = 'Hold';
        }
        if (status === 'TERMINATED') {
          color = 'red';
          text = 'Terminated';
        }
        return (
          <Tag color={color} key={status}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => {
        return convertDate(text);
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record: any) => (
        <Button
          type="primary"
          onClick={() => getSingleContractView(record.id!, clientID!)}
          size="small"
        >
          View
        </Button>
      ),
    },
  ];

  const { data: contracts, isLoading } = useQuery(
    ['contracts', page, size, filters, sessionStorage.getItem('customer')],
    () =>
      axiosPrivate({
        method: 'GET',
        url: `/contracts?customerId=${sessionStorage.getItem('customer')}&${
          filters || ''
        }`,
        params: {
          page,
          size,
        },
      })
  );

  // const { data: contracts, isLoading } = useQuery(
  //   ['contracts', page, size, filters],
  //   () => ContractService.getContracts(page, size, filters)
  // );

  const handleSearch = (e: any) => {
    setPage(0);
    setSize(10);
    const encodedValue = encodeURIComponent(e.trim());
    setFilters(`${searchField}=${encodedValue}`);
  };

  const handleChange = (pagination: any) => {
    setPage(pagination.current - 1);
    setSize(pagination.pageSize);
  };

  const setSearchTerms = (value: string) => {
    setSearchFieldSub(value);
    if (searchField === 'status') {
      setFilters(`${searchField}=${value}`);
    } else {
      setFilters(``);
    }
  };

  useEffect(() => {
    if (searchField === 'status') {
      setSearchFieldSub('Select Field');
      setDisableSearch(true);
      setSearchValue('');
    } else {
      setSearchFieldSub('Select Field');
      setSelectSearchTerms('');
      setDisableSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  const setMainSearchTerms = (value: string) => {
    setSearchField(value);
    setSelectSearchTerms(value);
    setSearchFieldSub('');
  };

  const selectBefore = (
    <div>
      <Select
        defaultValue="Select Field"
        placeholder="Select Field"
        value={searchField}
        onChange={(e) => setMainSearchTerms(e)}
        style={{ width: 200 }}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {searchFields?.map((item) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.value}
            </Option>
          );
        })}
      </Select>
      {searchField === 'status' && (
        <>
          <Select
            defaultValue="Select Field"
            placeholder="Select Field"
            value={searchFieldSub}
            onChange={(e) => setSearchTerms(e)}
            style={{ width: 200 }}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            {dropdownItems?.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
          <Button
            type="primary"
            size="small"
            className="clear-button"
            onClick={() => {
              setSearchValue('');
              setFilters('');
              setSearchField('Select Field');
              setSearchFieldSub('Select Field');
              setDisableSearch(false);
              setPage(0);
              setSize(10);
            }}
          >
            Clear Filter
            <CloseCircleOutlined />
          </Button>
        </>
      )}
    </div>
  );
  return (
    <Row className="h-full">
      <Col span={24}>
        <Card title="Contracts" className="h-full">
          <div className="search-bar">
            <Search
              addonBefore={selectBefore}
              placeholder="Search"
              value={searchValue}
              className={`${disableSearch ? 'disabled' : ''}`}
              enterButton="Search"
              onChange={(e) => setSearchValue(e.target.value)}
              size="large"
              allowClear
              onSearch={(e) => handleSearch(e)}
            />
          </div>
          <br />
          <Table
            columns={columns}
            rowKey={(record) => record.id!}
            dataSource={contracts?.data}
            loading={isLoading}
            onChange={handleChange}
            locale={{
              emptyText: <Empty description="No contracts" />,
            }}
            pagination={{
              defaultPageSize: 10,
              current: page + 1,
              pageSize: size,
              total: Number(contracts?.headers['x-total-count']),
              pageSizeOptions: ['10', '20', '50', '100'],
              showSizeChanger: true,
              hideOnSinglePage:
                Number(contracts?.headers['x-total-count']) <= size,
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ClientContractsTable;
