import React, { useEffect } from 'react';

import './Header.scss';
import { LoginOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Layout, Menu, MenuProps } from 'antd';
import { useLocation, Link } from 'react-router-dom';

import NCR from '../../assets/images/NCR_logo_without_background.png';
import useAuth from '../../hooks/useAuth';
import useLogout from '../../hooks/useLogout';
import * as ROUTES from '../../Routes/Routes';

const MainHeader = () => {
  const location = useLocation();
  const { auth } = useAuth();
  const logout = useLogout();
  const [admin, setAdmin] = React.useState(false);

  const handleLogout = async () => {
    await logout();
  };

  useEffect(() => {
    if (auth.roles?.includes('ncr-admin')) {
      setAdmin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link
          className={
            location.pathname === `${ROUTES.USER_PROFILE}` ? 'active' : ''
          }
          to={ROUTES.USER_PROFILE}
        >
          My Account
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Button type="link" size="small" onClick={() => handleLogout()}>
          <LogoutOutlined />
        </Button>
      ),
    },
  ];

  const mainMenuItems: MenuProps['items'] = [
    {
      key: '1',
      title: 'Home',
      label: (
        <Link
          className={
            location.pathname === `${ROUTES.DASHBOARD_NCR}` ? 'active' : ''
          }
          to={ROUTES.DASHBOARD_NCR}
        >
          Home
        </Link>
      ),
    },
    {
      key: '2',
      title: 'Assets',
      label: (
        <Link
          className={
            location.pathname === `${ROUTES.DASHBOARD_NCR}/${ROUTES.ASSETS}`
              ? 'active'
              : ''
          }
          to={ROUTES.ASSETS}
        >
          Assets
        </Link>
      ),
    },
    {
      key: '3',
      title: 'Contracts',
      label: (
        <Link
          className={
            location.pathname === `${ROUTES.DASHBOARD_NCR}/${ROUTES.CONTRACTS}`
              ? 'active'
              : ''
          }
          to={ROUTES.CONTRACTS}
        >
          Contracts
        </Link>
      ),
    },

    {
      key: '4',
      title: 'Customers',
      label: (
        <Link
          className={
            location.pathname.includes(
              `${ROUTES.DASHBOARD_NCR}/${ROUTES.CUSTOMERS}`
            ) === true
              ? 'active'
              : ''
          }
          to={ROUTES.CUSTOMERS}
        >
          Customers
        </Link>
      ),
    },
    {
      key: '5',
      title: 'Data Management',
      label: (
        <Link
          className={
            location.pathname.includes(`${ROUTES.DATA_MANAGEMENT}`) === true
              ? 'active'
              : ''
          }
          to={ROUTES.DATA_MANAGEMENT}
        >
          Data Management
        </Link>
      ),
    },
    // {
    //   key: '5',
    //   title: 'My Account',
    //   style: { marginLeft: 'auto' },
    //   label: (
    //     <Dropdown menu={{ items }} placement="bottomRight">
    //       <Avatar className="UserAvatar" icon={<UserOutlined />} />
    //     </Dropdown>
    //   ),
    // },
  ];

  return (
    <div className="top-banner shadow">
      <div className=" logo-container">
        <img
          className="h-6 w-20 mr-6 mt-0.5"
          src={NCR}
          alt="NCR-Logo-Transparent"
        />
        <p className="text-xl font-bold tracking-tight text-gray-900 logo-text">
          SLiAM
        </p>
      </div>
      {admin && (
        <div className="menu-container">
          <Menu
            mode="horizontal"
            theme="light"
            className="main-nav"
            items={mainMenuItems}
          />
          <Menu
            mode="horizontal"
            theme="light"
            className="main-nav sub-menu"
            items={items}
          />
        </div>
      )}
    </div>
  );
};
export default MainHeader;
function jwt(token: any): any {
  throw new Error('Function not implemented.');
}
