import React, { useEffect } from 'react';

import { SmileOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib/icons';
import { Card, Collapse, List, Alert, Tag, Button, Result, Spin } from 'antd';

import './AssetActions.scss';

import { AssetType } from '../../models/AssetType';
import { convertDate } from '../../utils/ConvertDate';
import Loader from '../Loader/Loader';

interface AssetActionsProps {
  assetID?: string;
  singleAsset?: AssetType;
  isLoading?: boolean;
}

const AssetActions: React.FC<AssetActionsProps> = ({
  assetID,
  singleAsset,
  isLoading,
}) => {
  const [actions, setAction] = React.useState<any>([]);

  const handleActions = () => {
    const data: any = [];
    if (singleAsset?.missingAttributes) {
      data.push({
        id: '1',
        title: 'Missing Attributes',
        description: 'The following mandatory fields are missing data.',
        data: singleAsset?.missingAttributes,
      });
    }
    if (
      singleAsset?.locationMismatched &&
      singleAsset?.newLocationScan === false
    ) {
      data.push({
        id: '2',
        title: 'Location Mismatch',
        description: 'Issue in Geo-Coordinate.',
      });
    }
    if (singleAsset?.requireContract) {
      data.push({
        id: '2',
        title: 'Require New Contract',
        description: 'The following asset requires a new contract.',
      });
    }
    if (singleAsset?.noAvailableContract) {
      data.push({
        id: '2',
        title: 'No Available Contract',
        description:
          'No active contract for this PID class service combination.',
      });
    }

    if (singleAsset?.newLocationScan && singleAsset?.locationMismatched) {
      data.push({
        id: '4',
        title: 'New Location Scanned',
        description: `Lat: ${singleAsset?.scanLatitude} Lng: ${
          singleAsset?.scanLongitude
        } at ${convertDate(singleAsset?.lastScannedTimeStamp)}`,
      });
    }
    return data;
  };

  useEffect(() => {
    const data = handleActions();
    setAction(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleAsset]);

  return (
    <Card title="Asset Actions" className="asset-actions">
      {isLoading && <Loader location="inside" />}
      {actions.length !== 0 ? (
        <List
          dataSource={actions}
          renderItem={(item: any) => (
            <List.Item className="action-item" key={item.title}>
              <p className="action-required">
                <Tag color="red">
                  [Action Required] <ExclamationCircleOutlined />
                </Tag>
              </p>
              <p className="action-title">{item.title}</p>
              <p className="action-description">{item.description}</p>
              <div className="action-atts">
                {typeof item.data !== 'boolean'
                  ? item?.data?.map((action: any) => (
                      <div className="col" key={`${item.title}-${action}`}>
                        <Alert
                          key={`${item.title}-${action}`}
                          message={action}
                          type="error"
                          showIcon
                        />
                      </div>
                    ))
                  : // <Alert
                    //   className="action-missing"
                    //   key={`${item.id}-${item.data}`}
                    //   message={item.data}
                    //   type="error"
                    //   showIcon
                    // />
                    null}
              </div>
            </List.Item>
          )}
        />
      ) : (
        <Result title="No actions required" />
      )}
    </Card>
  );
};

export default AssetActions;
