import { axiosPrivate } from '../api/axios';

export default class ContractService {
  static async getContracts(page: number, size: number, filters?: string) {
    return axiosPrivate({
      url: `/contracts${filters ? `?${filters}` : ''}`,
      method: 'GET',
      params: {
        page,
        size,
      },
    });
  }

  static async getContractById(id: string) {
    return axiosPrivate({
      url: `/contracts/${id}`,
      method: 'GET',
    });
  }

  static async getContractServices(
    contractId: string,
    page: number,
    size: number
  ) {
    return axiosPrivate({
      url: `/contracts/${contractId}/services`,
      method: 'GET',
    });
  }

  static async getContractHistory(
    id: string,
    page: number,
    size: number,
    filters?: string
  ) {
    return axiosPrivate({
      url: `/contracts/${id}/history?include=all${
        filters ? `&${filters}` : ''
      }`,
      method: 'GET',
      params: {
        page,
        size,
      },
    });
  }

  static async restoreSnapshot(id: string) {
    return axiosPrivate({
      url: `/contracts/${id}/history`,
      method: 'PUT',
    });
  }
}
