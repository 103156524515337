import React, { useState } from 'react';

import { BellOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  MenuProps,
  Row,
} from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { UserRole } from '../../../auth/AuthProvider';
import PersistLogin from '../../../auth/PersistLogin';
import RequireAuth from '../../../auth/RequireAuth';
import { useClient } from '../../../Context/ClientContextProvider';
import useLogout from '../../../hooks/useLogout';
import * as ROUTES from '../../../Routes/Routes';
import Footer from '../../Footer/Footer';
import MainHeader from '../../Header/Header';

import './DashboardNCRClient.scss';

const { SubMenu } = Menu;
const MenuItemGroup = Menu.ItemGroup;
const { Header, Content } = Layout;

const DashboardNCRClient: React.FC = () => {
  const location = useLocation();
  const { singleClient } = useClient();
  const logout = useLogout();

  const handleLogout = async () => {
    await logout();
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link
          className={
            location.pathname === `${ROUTES.CLIENT_USER_PROFILE}`
              ? 'active'
              : ''
          }
          to={ROUTES.CLIENT_USER_PROFILE}
        >
          My Profile
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Button type="link" size="small" onClick={() => handleLogout()}>
          Logout
        </Button>
      ),
    },
  ];

  const mainMenuItems: MenuProps['items'] = [
    {
      key: '0',
      title: 'Customer Name',
      label: (
        <span key="logo" className="company-name">
          {singleClient?.customerName}
        </span>
      ),
    },
    {
      key: '1',
      title: 'Home',
      label: (
        <Link
          className={
            location.pathname === `${ROUTES.DASHBOARD_NCR_CLIENT}`
              ? 'active'
              : ''
          }
          to={ROUTES.DASHBOARD_NCR_CLIENT}
        >
          Home
        </Link>
      ),
    },
    {
      key: '2',
      title: 'Assets',
      label: (
        <Link
          className={
            location.pathname === `${ROUTES.CLIENT_ASSETS}` ? 'active' : ''
          }
          to={ROUTES.CLIENT_ASSETS}
        >
          Assets
        </Link>
      ),
    },
    {
      key: '3',
      title: 'Contracts',
      label: (
        <Link
          className={
            location.pathname === `${ROUTES.CLIENT_CONTRACTS}` ? 'active' : ''
          }
          to={ROUTES.CLIENT_CONTRACTS}
        >
          Contracts
        </Link>
      ),
    },
    {
      key: '4',
      title: 'Sites',
      label: (
        <Link
          className={
            location.pathname === `${ROUTES.CLIENT_SITES}` ? 'active' : ''
          }
          to={ROUTES.CLIENT_SITES}
        >
          Sites
        </Link>
      ),
    },
    {
      key: '5',
      title: 'My Account',
      style: { marginLeft: 'auto' },
      label: (
        <Dropdown menu={{ items }} placement="bottomRight">
          <Avatar className="UserAvatar" size={45} icon={<UserOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <PersistLogin>
      <RequireAuth allowedRoles={[UserRole.client]}>
        <div className="site-layout">
          <Header>
            <Row>
              <Col span={24}>
                <MainHeader />
                <Menu
                  mode="horizontal"
                  theme="light"
                  className="main-nav client-nav"
                  items={mainMenuItems}
                />
              </Col>
            </Row>
          </Header>
          <section className="content">
            <Outlet />
          </section>
          <Footer />
        </div>
      </RequireAuth>
    </PersistLogin>
  );
};

export default DashboardNCRClient;
