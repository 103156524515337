import React from 'react';

import { Layout, Row, Col } from 'antd';
import { Outlet } from 'react-router-dom';

import { UserRole } from '../../../auth/AuthProvider';
import PersistLogin from '../../../auth/PersistLogin';
import RequireAuth from '../../../auth/RequireAuth';
import Footer from '../../Footer/Footer';
import MainHeader from '../../Header/Header';
import './DashboardNCR.scss';

const { Header, Content } = Layout;

const DashboardNCR: React.FC = () => {
  return (
    <div className="site-layout">
      <PersistLogin>
        <RequireAuth allowedRoles={[UserRole.admin]}>
          <Header>
            <Row>
              <Col span={24}>
                <MainHeader />
              </Col>
            </Row>
          </Header>
          <section className="content">
            <Outlet />
          </section>
          <Footer />
        </RequireAuth>
      </PersistLogin>
    </div>
  );
};

export default DashboardNCR;
