/* eslint-disable no-useless-catch */
import { axiosDefault } from '../api/axios';

/**
 * @remarks
 * Used for Login User.
 *
 * @returns
 * AxiosResponse which resolves either in an error or response data
 */

const loginUser = async (user: any) => {
  try {
    const response = await axiosDefault.post(`login`, user);
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * @remarks
 * Used to logout a user
 *
 * @returns
 * AxiosResponse which resolves either in an error or response data
 */

const logoutUser = async () => {
  try {
    const response = await axiosDefault.get(`logout`);
    return response;
  } catch (error) {
    throw error;
  }
};

export default { loginUser, logoutUser };
