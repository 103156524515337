/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useEffect, useState } from 'react';

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Tabs,
  notification,
  TablePaginationConfig,
  TabsProps,
  Tag,
  Skeleton,
} from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import './SingleClientContract.scss';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ContractService from '../../../APIServices/ContractService';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { AssetType } from '../../../models/AssetType';
import { ContractType } from '../../../models/ContractType';
import { ServiceType } from '../../../models/ServiceType';
import { convertDate } from '../../../utils/ConvertDate';
import ContractEntitlements from '../../ContractEntitlements/ContractEntitlements';
import NCRServicesTable from '../../NCR/NCRServicesTable/NCRServicesTable';
import ContractAssets from '../ContractAssets/ContractAssets';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const SingleClientContract = () => {
  const [singleContract, setSingleContract] = useState<ContractType>()!;
  const contractID: string = window.location.pathname.split('/').pop()!;

  const [loadingAssets, setLoadingAssets] = useState(false);
  const [assetData, setAssetData] = useState<AssetType[]>([]);
  const [assetMismatchData, setAssetMismatchData] = useState<AssetType[]>([]);
  const [assetVerifiedData, setAssetVerifiedData] = useState<AssetType[]>([]);
  const [serviceData, setServiceData] = useState<ServiceType[]>([]);
  const [contractService, setContractService] = useState<ServiceType>();
  const [current, setCurrent] = useState('');
  const axiosPrivate = useAxiosPrivate();
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [loadingTable, setLoadingTable] = useState(false);
  const onChange = (e: any) => {
    setCurrent(e.key);
  };
  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  const items: TabsProps['items'] = [
    {
      label: 'Entitlements',
      key: 'services',
      children: (
        <ContractEntitlements contractServices={singleContract?.services} />
      ),
    },
    {
      label: 'Assets',
      key: 'assets',
      children: (
        <ContractAssets assetData={singleContract?.assets} isCustomer />
      ),
    },
  ];

  const { refetch, isLoading, error } = useQuery(
    ['singleContract', contractID, singleContract],
    () => ContractService.getContractById(contractID),
    {
      onSuccess: (res) => {
        setSingleContract(res.data);
      },
    }
  );

  if (error) {
    openNotificationWithIcon(
      'error',
      'Failed to fetch single contract',
      'Something went wrong please try again later or contact support'
    );
  }

  useEffect(() => {
    refetch();
  }, [contractID]);

  const viewSingleCustomer = (customer: string, contract: string) => {
    sessionStorage.setItem('customer', customer);
    navigate(`/ncr/customers/${customer}/contracts/${contract}`);
  };
  return (
    <Row
      gutter={20}
      className="h-full"
      style={{ display: 'flex', flexWrap: 'wrap' }}
    >
      <Col xs={24} xl={4}>
        <Card id="ContractProps" title="Contract Information">
          {isLoading ? (
            <Skeleton />
          ) : (
            <ul className="AssetPropsList">
              <li>
                <p>
                  <span>Contract Status:</span>{' '}
                  {singleContract?.status === 'ACTIVE' ? (
                    <Tag color="green">Active</Tag>
                  ) : (
                    <Tag color="red">Inactive</Tag>
                  )}
                </p>
              </li>
              <li>
                <p>
                  <span>Contract Number:</span> {singleContract?.contractNbr}
                </p>
              </li>
              <li>
                <p>
                  <span>Contract Modifier: </span>{' '}
                  {singleContract?.contractModifier}
                </p>
              </li>
              <li>
                <p>
                  <span>Contract ID: </span> {singleContract?.ncrId}
                </p>
              </li>
              <li className="view-customer">
                <p>
                  <span>Customer: </span>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() =>
                      viewSingleCustomer(
                        singleContract?.customer?.id!,
                        singleContract?.id!
                      )
                    }
                  >
                    View Customer
                  </Button>
                </p>
              </li>
              <li>
                <p>
                  <span>Category:</span> {singleContract?.category}
                </p>
              </li>
              <li>
                <p>
                  <span>Start Date:</span> {}
                  {convertDate(singleContract?.startDate!)}
                </p>
              </li>
              <li>
                <p>
                  <span>End Date:</span> {}
                  {convertDate(singleContract?.endDate!)}
                </p>
              </li>
              <li>
                <p>
                  <span>Last Updated:</span> {}
                  {convertDate(singleContract?.updatedAt!)}
                </p>
              </li>
            </ul>
          )}
        </Card>
      </Col>
      <Col xs={24} xl={20}>
        <Tabs
          defaultActiveKey="1"
          id="singleClientContractTabs"
          className="custom-tabs"
          items={items}
          onChange={(e) => onChange}
        />
        <br />
      </Col>
    </Row>
  );
};

export default SingleClientContract;
