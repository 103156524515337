import axios from 'axios';

export const BASE_URL = `${process.env.REACT_APP_API_URL}`;

export const axiosDefault = axios.create({
  baseURL: BASE_URL,
});

export const axiosMultipart = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data text/any',
  },
  withCredentials: true,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
