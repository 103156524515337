import React, { useRef, useState } from 'react';

import { UserOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Breadcrumb,
  Card,
  Avatar,
  Button,
  Input,
  RadioChangeEvent,
  Radio,
  Menu,
  MenuProps,
  Form,
} from 'antd';
import './NCRUserProfile.scss';
import Title from 'antd/lib/typography/Title';
import { Link } from 'react-router-dom';

import { axiosPrivate } from '../../../api/axios';
import uploadIcon from '../../../assets/images/upload-icon.png';
import * as ROUTES from '../../../Routes/Routes';

const NCRUserProfilePassword = () => {
  const [itemDisabled, setItemDisabled] = useState<boolean>(true);
  const userData = sessionStorage.getItem('user');
  const [user, setUser] = useState<any>(userData ? JSON.parse(userData) : null);
  const [newPass, setNewPass] = useState<any>();
  const [newFirstName, setNewFirstName] = useState<any>();
  const [newLastName, setNewLastName] = useState<any>();
  const [confirmPass, setConfirmPass] = useState<any>();
  const errRef = useRef<HTMLParagraphElement>(null);
  const confirmErrRef = useRef<HTMLParagraphElement>(null);
  const [goodPassword, setGoodPassword] = useState<boolean>(false);
  const [matchPassword, setMatchPassword] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');
  const [confirmErrMsg, setConfirmErrMsg] = useState<string>('');

  const checkPassword = (str: string) => {
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const number = '1234567890';
    const special = '!@#$%^&*()+-_[]{}?/.,<>`~';
    let containsUpper = false;
    let containsLower = false;
    let containsNumber = false;
    let containsSpecial = false;

    for (let i = 0; i < str.length; i += 1) {
      if (uppercase.includes(str[i])) {
        containsUpper = true;
      }
      if (lowercase.includes(str[i])) {
        containsLower = true;
      }
      if (number.includes(str[i])) {
        containsNumber = true;
      }
      if (special.includes(str[i])) {
        containsSpecial = true;
      }
    }
    if (
      str.length >= 8 &&
      containsUpper &&
      containsLower &&
      containsNumber &&
      containsSpecial
    ) {
      setNewPass(str);
      setGoodPassword(true);
      setErrMsg('');
    } else {
      setNewPass(str);
      setErrMsg(
        'Password must include at least one uppercase, one lowercase, one number, one special character, and be at least 8 characters long'
      );
      setGoodPassword(false);
    }
  };

  const checkConfirmPassword = (confirm: string) => {
    if (confirm === newPass) {
      setMatchPassword(true);
      setConfirmErrMsg('');
    } else {
      setMatchPassword(false);
      setConfirmErrMsg('Passwords must match');
    }
  };

  const updateUserPassword = async (passwordData: string) => {
    console.log('update password');
    try {
      axiosPrivate({
        method: 'PATCH',
        url: `/users/reset-credential`,
        data: { password: passwordData },
      }).then((response) => {
        console.log(response);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Card
          title={
            <Title
              style={{
                fontSize: 'xx-large',
                color: '#5a5a5a',
                marginTop: '25px',
                marginLeft: '15px',
              }}
            >
              Update Password
              <p
                style={{
                  fontSize: 'small',
                  color: '#777777',
                  marginTop: '10px',
                }}
              >
                Password must be more than 8 characters long, and contain at
                least one uppercase letter, one lowercase letter, one number,
                and one <br /> special character.
              </p>
            </Title>
          }
          id="UpdateProps"
        >
          <Form layout="vertical" style={{ marginLeft: '15px' }}>
            <Form.Item label="Enter New Password">
              <p
                ref={errRef}
                className={errMsg ? 'text-red-400' : 'hidden'}
                aria-live="assertive"
              >
                {errMsg}
              </p>
              <Input
                placeholder="Enter New Password"
                status={goodPassword ? '' : 'error'}
                onChange={(e) => checkPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Confirm Password">
              <p
                ref={confirmErrRef}
                className={confirmErrMsg ? 'text-red-400' : 'hidden'}
                aria-live="assertive"
              >
                {confirmPass === newPass ? '' : 'Passwords must match'}
              </p>
              <Input
                placeholder="Confirm Password"
                status={matchPassword ? '' : 'error'}
                onChange={(e) => checkConfirmPassword(e.target.value)}
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            size="middle"
            style={{ marginLeft: '15px' }}
            disabled={!(goodPassword && matchPassword)}
            onClick={() => updateUserPassword(newPass)}
          >
            Save Password
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default NCRUserProfilePassword;
