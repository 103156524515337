/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  Col,
  Row,
  Modal,
  Form,
  Input,
  Radio,
  Select,
  Drawer,
  Tabs,
  TabsProps,
  notification,
  Tag,
  Skeleton,
} from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Controller, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

import './SingleAssetClient.scss';
import AssetService from '../../../APIServices/AssetService';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useTagsWithColors } from '../../../hooks/useFlags';
import { AssetType } from '../../../models/AssetType';
import { ContractType } from '../../../models/ContractType';
import { samplePinDataObj } from '../../../models/PinDataType';
import { ServiceType } from '../../../models/ServiceType';
import { convertDate } from '../../../utils/ConvertDate';
import statusUtil from '../../../utils/statusUtil';
import AssetActionHistory from '../../AssetActionHistory/AssetActionHistory';
import AssetActions from '../../AssetActions/AssetActions';
import AssetLocationHistory from '../../AssetLocationHistory/AssetLocationHistory';
import AssetPendingTasks from '../../AssetsPendingTasks/AssetsPendingTasks';
import NCRContractsTable from '../../NCR/NCRContractsTable/NCRContractsTable';
import NCRServicesTable from '../../NCR/NCRServicesTable/NCRServicesTable';
import ClientSitesTable from '../ClientSitesTable/ClientSitesTable';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const SingleAssetClient = () => {
  const [current, setCurrent] = useState('action-required');
  const [mapData, setMapData] = useState<samplePinDataObj[]>([]);
  const [openRetireAssetModal, setOpenRetireAssetModal] = useState(false);
  const [openUpdateAssetModal, setOpenUpdateAssetModal] = useState(false);
  const assetID: string = window.location.pathname.split('/').pop()!;
  const [successfulAlert, setSuccessfulAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [serviceData, setServiceData] = useState<ServiceType[]>();
  const [successfulAssetCreation, setSuccessfulAssetCreation] = useState(false);
  const [errorAssetCreation, setErrorAssetCreation] = useState(false);
  const contractID: string = window.location.pathname.split('/').pop()!;
  const [loadingServices, setLoadingServices] = useState(false);
  const [openContractsModal, setOpenContractsModal] = useState(false);
  const [assetContracts, setAssetContracts] = useState<ContractType[]>([]);
  const [siteModal, setSiteModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState<any>();
  const queryClient = useQueryClient();
  const [updated, setUpdated] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  const openViewContracts = (contractData: [ContractType]) => {
    setAssetContracts(contractData);
    setOpenContractsModal(true);
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  const onChange = (e: any) => {
    setCurrent(e.key);
  };

  const { data: singleAsset, isLoading } = useQuery(
    ['singleAsset', assetID],
    () =>
      axiosPrivate({
        method: 'GET',
        url: `/assets/${assetID}`,
      })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          openNotificationWithIcon(
            'error',
            'Failed to get single asset',
            'Something went wrong please try again later or contact support'
          );
          console.log(error);
        })
  );
  const tags = useTagsWithColors(singleAsset);
  const items: TabsProps['items'] = [
    {
      label: 'Action Required',
      key: 'action-required',
      children: <AssetActions singleAsset={singleAsset} />,
    },
    // {
    //   label: 'Pending Tasks',
    //   key: 'pending-tasks',
    //   children: <AssetPendingTasks assetId={singleAsset?.id!} />,
    // },
    {
      label: 'Entitlements',
      key: 'asset-services',
      children: <NCRServicesTable tableData={singleAsset?.services!} />,
    },
    {
      label: 'Asset History',
      key: 'asset-history',
      children: <AssetActionHistory singleAsset={singleAsset!} tab={current} />,
    },
    {
      label: 'Location History',
      key: 'location-history',
      children: (
        <AssetLocationHistory singleAsset={singleAsset!} tab={current} />
      ),
    },
  ];

  const deleteAsset = async () => {
    setLoadingDelete(true);
    try {
      axiosPrivate({
        method: 'DELETE',
        url: `/assets/${assetID}`,
      }).then((response) => {
        setTimeout(() => {
          navigate(`/ncr/assets/`);
          setLoadingDelete(false);
        }, 3000);
        openNotificationWithIcon(
          'success',
          'Success',
          'Asset deletion successful'
        );
      });
    } catch (error) {
      setLoadingDelete(false);
      openNotificationWithIcon('error', 'Failed', 'Asset deletion failed');
      console.log(error);
    }
  };

  const onUpdateAsset = async (data: any) => {
    try {
      const { data: response } = await AssetService.updateAsset(
        singleAsset.id,
        data
      );
      openNotificationWithIcon('success', 'Success', 'Asset update successful');
      queryClient.clear();
      return response;
    } catch (error) {
      openNotificationWithIcon('error', 'Failed', 'Asset update failed');
      throw error;
    }
  };

  useEffect(() => {
    setMapData(mapData);
  }, [mapData, setMapData]);

  const contractsAction = (data: any) => {
    if (data?.length >= 1) {
      return (
        <Button
          type="link"
          size="small"
          onClick={() => openViewContracts(data!)}
        >
          View Contracts
        </Button>
      );
    }
    return (
      <Tag color="volcano" key={1}>
        No Contract
      </Tag>
    );
  };

  const selectSiteForForm = (id: string) => {
    setSelectedSite(id);
    setSiteModal(false);
    setValue('site.id', id, { shouldValidate: true });
  };

  return (
    <Row gutter={20} className="h-full">
      {contextHolder}
      <Col span={24}>
        <div className="asset-cta-toolbar">
          <div className="asset-action-buttons">
            <Button
              className="updateAssetButton"
              type="primary"
              size="large"
              onClick={() => setOpenUpdateAssetModal(true)}
            >
              Update Asset
            </Button>
            <Button
              className="updateAssetButton"
              type="primary"
              size="large"
              onClick={() => setOpenRetireAssetModal(true)}
            >
              Retire Asset
            </Button>
          </div>
        </div>
      </Col>
      <Col span={4}>
        <Card title="Asset Information">
          {isLoading ? (
            <Skeleton />
          ) : (
            <ul className="AssetPropsList">
              <li>
                <p>
                  {' '}
                  <span>Asset Status: </span>
                  {statusUtil(singleAsset?.status!)}
                </p>
              </li>
              <li className="flag-tags">
                <p>
                  <span>Asset Flags: </span>
                  {tags.map((tag) => (
                    <Tag color={tag.color} key={tag.tag}>
                      {tag.text}
                    </Tag>
                  ))}
                </p>
              </li>
              <li>
                <p>
                  <span>Contracts: </span>
                  {singleAsset?.requireContract! ? (
                    <Tag color="volcano" key={1}>
                      Require New Contract
                    </Tag>
                  ) : (
                    contractsAction(singleAsset?.contracts)
                  )}
                </p>
              </li>
              <li>
                <p>
                  <span>ID: </span>
                  {singleAsset?.id}
                </p>
              </li>

              <li>
                <p>
                  <span>Item Instance: </span>
                  {singleAsset?.itemInstance}
                </p>
              </li>
              <li>
                <p>
                  <span>Product ID: </span>
                  {singleAsset?.productId}
                </p>
              </li>
              <li>
                <p>
                  <span>Serial Number: </span>
                  {singleAsset?.serialNbr}
                </p>
              </li>
              <li>
                <p>
                  <span>Software Key ID: </span>
                  {singleAsset?.softwareKeyId}
                </p>
              </li>
              <li>
                <p>
                  <span>Address: </span>
                  {singleAsset?.site?.addressLine1}, {singleAsset?.site?.city},{' '}
                  {singleAsset?.site?.state},{singleAsset?.site?.postalCode},{' '}
                  {singleAsset?.site?.country}
                </p>
              </li>
              <li>
                <p>
                  <span>Retired: </span>
                  {singleAsset?.retired ? 'True' : 'False'}
                </p>
              </li>

              <li>
                <p>
                  <span>Created: </span>
                  {convertDate(singleAsset?.createdAt!)}
                </p>
              </li>
              <li>
                <p>
                  <span>Last Updated: </span>
                  {convertDate(singleAsset?.updatedAt!)}
                </p>
              </li>
            </ul>
          )}
        </Card>
      </Col>
      <Col span={20}>
        <Tabs
          defaultActiveKey="1"
          className="custom-tabs"
          onTabClick={(key) => onChange(key)}
          items={items}
          activeKey={current}
        />
        <br />
      </Col>
      <Drawer
        title="Update Asset"
        open={openUpdateAssetModal}
        placement="right"
        size="large"
        closable
        onClose={() => setOpenUpdateAssetModal(false)}
        footer={[
          <Button
            key="back"
            size="large"
            onClick={() => setOpenUpdateAssetModal(false)}
          >
            Cancel
          </Button>,
          <Button
            style={{ marginLeft: '10px' }}
            key="submit"
            type="primary"
            size="large"
            htmlType="submit"
            form="updateAsset"
          >
            Update Asset
          </Button>,
        ]}
      >
        <form id="updateAsset" onSubmit={handleSubmit(onUpdateAsset)}>
          <Form.Item label="Item Instance">
            <Controller
              name="itemInstance"
              control={control}
              defaultValue={singleAsset?.itemInstance}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter item instance"
                  aria-required="true"
                />
              )}
            />
            {errors.itemInstance?.type === 'required' && (
              <p role="alert">Item Instance is required</p>
            )}
          </Form.Item>
          <Form.Item label="Asset Type">
            <Controller
              name="assetType"
              control={control}
              defaultValue={singleAsset?.assetType}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} placeholder="Select Asset Type">
                  <Select.Option value="ATM">ATM</Select.Option>
                </Select>
              )}
            />
            {errors.assetType?.type === 'required' && (
              <p role="alert">Asset Type is required</p>
            )}
          </Form.Item>

          <Form.Item label="Software Key ID">
            <Controller
              name="softwareKeyId"
              control={control}
              defaultValue={singleAsset?.softwareKeyId}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Software Key ID"
                  aria-required="true"
                />
              )}
            />
            {errors.softwareKeyId?.type === 'required' && (
              <p role="alert">Software Key ID is required</p>
            )}
          </Form.Item>
          <Form.Item label="Retired" hidden>
            <Input
              value={singleAsset?.retired === false ? 'false' : 'true'}
              hidden
            />
          </Form.Item>
          <Form.Item label="Product ID">
            <Controller
              name="productId"
              control={control}
              defaultValue={singleAsset?.productId}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled
                  placeholder="Enter Product ID"
                  aria-required="true"
                />
              )}
            />
            {errors.productId?.type === 'required' && (
              <p role="alert">Product ID is required</p>
            )}
          </Form.Item>
          <Form.Item label="Serial Number">
            <Controller
              name="serialNbr"
              control={control}
              defaultValue={singleAsset?.serialNbr}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled
                  placeholder="Enter Serial Number"
                  aria-required="true"
                />
              )}
            />
            {errors.serialNbr?.type === 'required' && (
              <p role="alert">Serial Number is required</p>
            )}
          </Form.Item>
          <Form.Item label="Status">
            <Controller
              name="status"
              defaultValue={singleAsset?.status}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input {...field} disabled placeholder="Enter Status" />
              )}
            />
            {errors.status?.type === 'required' && (
              <p role="alert">Status is required</p>
            )}
          </Form.Item>
          <Form.Item label="Asset Active">
            <Controller
              name="active"
              defaultValue={singleAsset?.active!.toString()}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} placeholder="Select Active">
                  <Select.Option value="true">True</Select.Option>
                  <Select.Option value="false">False</Select.Option>
                </Select>
              )}
            />
            {errors.active?.type === 'required' && (
              <p role="alert">Active is required</p>
            )}
          </Form.Item>
          <Form.Item label="Site ID">
            <Controller
              name="site.id"
              control={control}
              defaultValue={singleAsset?.site?.id}
              rules={{ required: true }}
              render={({ field }) => (
                <div className="search-field">
                  <Input
                    {...field}
                    placeholder="Enter Site Number"
                    onClick={() => setSiteModal(true)}
                  />
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => setSiteModal(true)}
                  >
                    Search
                  </Button>
                </div>
              )}
            />
          </Form.Item>
        </form>
      </Drawer>
      <Modal
        title="Retire Asset"
        open={openRetireAssetModal}
        onCancel={() => setOpenRetireAssetModal(false)}
        footer={[
          <Button
            key="back"
            size="large"
            onClick={() => setOpenRetireAssetModal(false)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            size="large"
            danger
            loading={loadingDelete}
            onClick={() => deleteAsset()}
          >
            Retire Asset
          </Button>,
        ]}
      >
        Are you sure you want to Remove these ATM(s)? This action will remove
        the selected ATMs and all associated information from the ledger. If you
        wish to Retire any ATMs, select Update ATM and set Retired to True. This
        action cannot be undone!
      </Modal>{' '}
      <Modal
        title="Asset Contracts"
        open={openContractsModal}
        onCancel={() => setOpenContractsModal(false)}
        width="80%"
        footer={[
          <Button
            key="back"
            size="large"
            onClick={() => setOpenContractsModal(false)}
          >
            Close
          </Button>,
        ]}
      >
        <NCRContractsTable
          tableData={assetContracts}
          isCustomer
          searchFields={[
            { id: 'status', value: 'Status' },
            { id: 'contractNbr', value: 'Contract Number' },
          ]}
        />
      </Modal>
      <Modal
        title="Select Site 2"
        open={siteModal}
        onCancel={() => setSiteModal(false)}
        width="80%"
        footer={[
          <Button key="back" size="large" onClick={() => setSiteModal(false)}>
            Close
          </Button>,
        ]}
      >
        <ClientSitesTable
          tableAction={selectSiteForForm}
          siteSelector
          isCustomer
        />
      </Modal>
    </Row>
  );
};

export default SingleAssetClient;
