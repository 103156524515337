/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';

import {
  DollarCircleOutlined,
  RightSquareOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  AreaChartOutlined,
  FileExclamationOutlined,
  RedoOutlined,
} from '@ant-design/icons/lib/icons';
import { Card, Tag } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import './NCRClientHome.scss';

import { useClient } from '../../../Context/ClientContextProvider';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { CustomerType } from '../../../models/CustomerType';
import NCRAssetTable from '../NCRAssetTable/NCRAssetTable';

const NCRClientHome = () => {
  const { singleClient } = useClient();
  const { auth } = useAuth();
  // const [assetPending, setAssetPending] = useState('0');
  const [tableFilter, setTableFilter] = useState('autoUpdated=true');
  const navigate = useNavigate();
  const location = useLocation();
  const [tableTitle, setTableTitle] = useState('Auto Updates');
  const [customerData, setCustomerData] = useState<CustomerType>();
  const lastSegment = location.pathname.split('/').pop();
  const axiosPrivate = useAxiosPrivate();
  const id = sessionStorage.getItem('customer');

  const getCustomerById = async () => {
    axiosPrivate({
      method: 'GET',
      url: `customers/${id}`,
    })
      .then((response: any) => {
        setCustomerData(response.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCustomerById();
  }, []);

  // const fetchAssetByStatus = async (status: string) => {
  //   axiosPrivate({
  //     method: 'GET',
  //     url: `assets?customerId=${id}&${status}`,
  //   })
  //     .then((response: any) => {
  //       if (status === 'status=PendingApproval') {
  //         setAssetPending(response.headers['x-total-count']);
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.log(error);
  //     });
  // };
  //
  // useEffect(() => {
  //   fetchAssetByStatus('status=PendingApproval');
  // }, []);

  const getSingleAssetView = (assetID: string) => {
    if (lastSegment === 'overview') {
      navigate(`/ncr/customers/${id}/assets/${assetID}`);
    } else {
      navigate(`assets/${assetID}`);
    }
  };

  const setTableFilterByStatus = (status: { value: string; name: string }) => {
    setTableFilter(status.name);
    setTableTitle(status.value);
  };

  return (
    <>
      <div className="dashboard-tiles">
        <Card
          title="Auto Updates"
          className="total-card"
          onClick={() =>
            setTableFilterByStatus({
              value: 'Auto Update Assets',
              name: 'autoUpdated=true',
            })
          }
          extra={
            <Tag color="blue" className="custom-tag">
              <RedoOutlined />
            </Tag>
          }
        >
          <p className="status-title">
            {customerData?.autoUpdatedAssetCount === undefined
              ? 0
              : customerData?.autoUpdatedAssetCount}
          </p>
        </Card>

        <Card
          title="Auto Moved"
          className="moved-card"
          onClick={() =>
            setTableFilterByStatus({
              value: 'Auto Moved Assets',
              name: 'autoMoved=true',
            })
          }
          extra={
            <Tag color="green" className="custom-tag">
              <RightSquareOutlined />
            </Tag>
          }
        >
          <p className="status-title">
            {customerData?.autoMovedAssetCount === undefined
              ? 0
              : customerData?.autoMovedAssetCount}
          </p>
        </Card>
        <Card
          title="Action Required"
          className="leaks-card"
          onClick={() =>
            setTableFilterByStatus({
              value: 'Action Required Assets',
              name: 'actionRequired=true',
            })
          }
          extra={
            <Tag color="volcano" className="custom-tag">
              <ExclamationCircleOutlined />
            </Tag>
          }
        >
          <p className="status-title">
            {customerData?.actionRequiredAssetCount === undefined
              ? 0
              : customerData?.actionRequiredAssetCount}
          </p>
        </Card>

        {/* <Card */}
        {/*  title="Pending Approval" */}
        {/*  onClick={() => */}
        {/*    setTableFilterByStatus({ */}
        {/*      value: 'Assets Pending Approval', */}
        {/*      name: 'status=PendingApproval', */}
        {/*    }) */}
        {/*  } */}
        {/*  className="pending-card" */}
        {/*  extra={ */}
        {/*    <Tag color="yellow" className="custom-tag"> */}
        {/*      <FileDoneOutlined /> */}
        {/*    </Tag> */}
        {/*  } */}
        {/* > */}
        {/*  <p className="status-title">{assetPending}</p> */}
        {/* </Card> */}

        <Card
          title="Total"
          onClick={() =>
            setTableFilterByStatus({
              value: 'All Assets',
              name: '',
            })
          }
          className="total-card"
          extra={
            <Tag color="default" className="custom-tag">
              <AreaChartOutlined />
            </Tag>
          }
        >
          <p className="status-title">{singleClient?.assetCount}</p>
        </Card>
      </div>
      <br />
      <NCRAssetTable
        tableTitle={tableTitle}
        tableAction={getSingleAssetView}
        isCustomer
        tableFilter={tableFilter}
      />
    </>
  );
};

export default NCRClientHome;
