import React, { useEffect } from 'react';

import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';

import NCRAssetTable from '../NCRAssetTable/NCRAssetTable';
import NCRClientTable from '../NCRCustomerTable/NCRCustomerTable';

import './NCRHome.scss';

const NCRHome = () => {
  const navigate = useNavigate();

  const getSingleAssetView = (assetID: string, customerID: string) => {
    navigate(`/ncr/customers/${customerID}/assets/${assetID}`);
    sessionStorage.setItem('customer', customerID);
  };

  useEffect(() => {
    sessionStorage.setItem('customer', '');
  }, []);
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <NCRClientTable
            searchFields={[
              { id: 'customerName', value: 'Customer Name' },
              { id: 'customerNbr', value: 'Customer Number' },
            ]}
          />
        </Col>
      </Row>
      <br />
      <Row gutter={16} id="ncr-home-assets">
        <Col span={24}>
          <NCRAssetTable
            tableTitle="Latest Updates"
            tableFilter="autoUpdated=true"
            tableAction={getSingleAssetView}
            viewCustomer
          />
        </Col>
      </Row>
    </>
  );
};

export default NCRHome;
