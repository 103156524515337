/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  Form,
  Input,
  Radio,
  Select,
  Drawer,
  notification,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import states from '../../../assets/dataStates';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { ServiceType } from '../../../models/ServiceType';
import { SiteType } from '../../../models/SiteType';
import AssetMap from '../../AssetMap/AssetMap';
import './ClientSingleSite.scss';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const ClientSingleSite = () => {
  const [current, setCurrent] = useState('mail');
  const [openRetireSiteModal, setOpenRetireSiteModal] = useState(false);
  const [openUpdateSiteModal, setOpenUpdateSiteModal] = useState(false);
  const [singleSite, setSingleSite] = useState<SiteType>();
  const [updatedSite, setUpdatedSite] = useState<SiteType>();
  const siteID: string = window.location.pathname.split('/').pop()!;
  const customerID: string = window.location.pathname.split('/').slice(-3)[0];
  const [successfulAlert, setSuccessfulAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [serviceData, setServiceData] = useState<ServiceType[]>();
  const contractID: string = window.location.pathname.split('/').pop()!;
  const [loadingServices, setLoadingServices] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [deleteMessage, setDeleteMessage] = useState(
    ' Are you sure you want to Delete this site? This action will remove the selected site and all associated information from the ledger. If you wish to cancel any Sites this action cannot be undone!'
  );

  const {
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitSuccessful, isValid },
  } = useForm();

  const emptyFieldsOnCancel = () => {
    reset();
    setOpenUpdateSiteModal(false);
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  // `contracts/${contractID}/services`

  const fetchSiteData = async () => {
    setLoadingServices(true);
    try {
      axiosPrivate({
        method: 'GET',
        url: `contracts/${contractID}/services`,
      }).then((response) => {
        setServiceData(response.data);
        setLoadingServices(false);
      });
    } catch (error: any) {
      console.log(error);
      setLoadingServices(false);
    }
  };

  const onChange = (e: any) => {
    setCurrent(e.key);
  };
  const fetchSingleSite = async () => {
    try {
      axiosPrivate({
        method: 'GET',
        url: `sites/${siteID}`,
      }).then((response) => {
        setSingleSite(response.data);
        setUpdatedSite(response.data);
        fetchSiteData();
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSingleSite();
  }, []);

  const deleteSite = async () => {
    setLoadingDelete(true);
    try {
      axiosPrivate({
        method: 'DELETE',
        url: `sites/${siteID}`,
      }).then((response) => {
        setSuccessfulAlert(true);
        setTimeout(() => {
          navigate(`/ncr/customers/${customerID}/assets/`);
          setLoadingDelete(false);
        }, 3000);
        openNotificationWithIcon(
          'success',
          'Success',
          'Site deletion successful'
        );
      });
    } catch (error) {
      setLoadingDelete(false);
      openNotificationWithIcon('error', 'Failed', 'Site deletion failed');
      setErrorAlert(true);
      console.log(error);
    }
  };

  const updateSite = async (siteData: any) => {
    try {
      axiosPrivate({
        method: 'PUT',
        url: `sites/${siteID}`,
        data: { ...singleSite, ...siteData },
      }).then((response) => {
        setSingleSite(response.data);
        setSuccessfulAlert(true);
        openNotificationWithIcon(
          'success',
          'Success',
          'Site update successfully'
        );
        setLoading(false);
        setOpenUpdateSiteModal(false);
      });
    } catch (error) {
      setErrorAlert(true);
      openNotificationWithIcon('error', 'Failed', 'Site update failed');
      setLoading(false);
    }
  };

  const convertDate = (date: any) => {
    const datetime = new Date(date);
    return datetime.toLocaleDateString();
  };

  return (
    <Row
      gutter={20}
      className="h-full"
      style={{ display: 'flex', flexWrap: 'wrap' }}
    >
      {contextHolder}
      <Col span={24}>
        <div className="asset-cta-toolbar">
          <div className="asset-action-buttons">
            <Button
              className="updateSiteButton"
              type="primary"
              size="large"
              onClick={() => setOpenUpdateSiteModal(true)}
              hidden
            >
              Update Site
            </Button>
            <Button
              className="updateSiteButton"
              type="primary"
              size="large"
              onClick={() => setOpenRetireSiteModal(true)}
              hidden
            >
              Retire Site
            </Button>
          </div>
        </div>
      </Col>
      <Col xs={24} xl={8}>
        <Card title="Site Information">
          <ul className="SitePropsList">
            <li>
              <p>
                <span>ID: </span>
                {singleSite?.ncrSiteId}
              </p>
            </li>
            <li>
              <p>
                <span>Name: </span>
                {singleSite?.name}
              </p>
            </li>
            <li>
              <p>
                <span>Branch No: </span>
                {singleSite?.branchNo}
              </p>
            </li>
            {/* <li>
              <p>
                <span>Site Pulse: </span>
                {singleSite?.sitePulse}
              </p>
            </li> */}
            <li>
              <p>
                <span>Site Duns: </span>
                {singleSite?.siteDuns}
              </p>
            </li>
            <li>
              <p>
                <span>Original Corp: </span>
                {singleSite?.originalCorp}
              </p>
            </li>
            <li>
              <p>
                <span>Address: </span>
                {singleSite?.addressLine1},{singleSite?.city},{' '}
                {singleSite?.state},{singleSite?.postalCode},{' '}
                {singleSite?.country}
              </p>
            </li>
            <li>
              <p>
                <span>Site Coordinates: </span>
                {singleSite?.geotag[0].concat(', ', singleSite?.geotag[1])}
              </p>
            </li>
            <li>
              <p>
                <span>Site Usage: </span>
                {singleSite?.siteUsage}
              </p>
            </li>
            <li>
              <p>
                <span>Channel Org Code: </span>
                {singleSite?.channelOrgCode}
              </p>
            </li>
            <li>
              <p>
                <span>Site Preferred Language: </span>
                {singleSite?.sitePrefLang}
              </p>
            </li>
            <li>
              <p>
                <span>Created: </span>
                {convertDate(singleSite?.createdAt)}
              </p>
            </li>
            <li>
              <p>
                <span>Last Updated: </span>
                {convertDate(singleSite?.updatedAt)}
              </p>
            </li>
          </ul>
        </Card>
      </Col>
      <Col xs={24} xl={16}>
        <Card title="Current Site Location">
          <AssetMap siteData={[singleSite!]} height={576} />
        </Card>
      </Col>
      <Drawer
        title="Update Site"
        open={openUpdateSiteModal}
        placement="right"
        size="large"
        closable
        onClose={() => setOpenUpdateSiteModal(false)}
        footer={[
          <Button key="back" size="large" onClick={() => emptyFieldsOnCancel()}>
            Cancel
          </Button>,
          <Button
            style={{ marginLeft: '10px' }}
            key="submit"
            type="primary"
            size="large"
            htmlType="submit"
            disabled={!isValid}
            form="updateSite"
          >
            Update Site
          </Button>,
        ]}
      >
        <form id="updateSite" onSubmit={handleSubmit(updateSite)}>
          <Form.Item
            label={
              <span>
                <span>Name</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              defaultValue={singleSite?.name}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Site Name"
                  aria-required="true"
                />
              )}
            />
            {errors.name?.type === 'required' && (
              <p role="alert">Site Name is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Branch Number</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="branchNo"
              control={control}
              defaultValue={singleSite?.branchNo}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Branch Number"
                  aria-required="true"
                />
              )}
            />
            {errors.branchNo?.type === 'required' && (
              <p role="alert">Branch Number is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Site Status</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="siteStatus"
              control={control}
              rules={{ required: true }}
              defaultValue={singleSite?.siteStatus}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Site Status"
                  disabled
                  aria-required="true"
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Geotag</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="geotag[0]"
              control={control}
              rules={{ required: true }}
              defaultValue={singleSite?.geotag[0]}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Geotag Latitude"
                  aria-required="true"
                />
              )}
            />
            <Controller
              name="geotag[1]"
              control={control}
              rules={{ required: true }}
              defaultValue={singleSite?.geotag[1]}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Geotag Longitude"
                  aria-required="true"
                />
              )}
            />
            {errors.geotag?.type === 'required' && (
              <p role="alert">Geotag is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Expected Address</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="addressLine1"
              control={control}
              rules={{ required: true }}
              defaultValue={singleSite?.addressLine1}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Site Address"
                  aria-required="true"
                />
              )}
            />
            {errors.addressLine1?.type === 'required' && (
              <p role="alert">Address is required</p>
            )}
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              defaultValue={singleSite?.city}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter City"
                  aria-required="true"
                />
              )}
            />
            {errors.city?.type === 'required' && (
              <p role="alert">City is required</p>
            )}
            <Controller
              name="state"
              control={control}
              defaultValue={singleSite?.state}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Enter State"
                  aria-required="true"
                >
                  {states.map((state: any) => (
                    <Select.Option
                      key={state.abbreviation}
                      value={state.abbreviation}
                    >
                      {state.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
            {errors.state?.type === 'required' && (
              <p role="alert">State is required</p>
            )}
            <Controller
              name="postalCode"
              control={control}
              rules={{ required: true }}
              defaultValue={singleSite?.postalCode}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Zip Code"
                  aria-required="true"
                />
              )}
            />
            {errors.postalCode?.type === 'required' && (
              <p role="alert">Zipcode is required</p>
            )}
            <Controller
              name="province"
              control={control}
              defaultValue={singleSite?.province}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Province"
                  aria-required="false"
                />
              )}
            />
            <Controller
              name="country"
              control={control}
              rules={{ required: true }}
              defaultValue={singleSite?.country}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Country"
                  aria-required="true"
                />
              )}
            />
            {errors.country?.type === 'required' && (
              <p role="alert">Country is required</p>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Service Location Code</span>
                <span style={{ color: '#FF0000' }}> * </span>
              </span>
            }
          >
            <Controller
              name="serviceLocationCode"
              control={control}
              defaultValue={singleSite?.serviceLocationCode}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter Service Location Code"
                  aria-required="true"
                />
              )}
            />
            {errors.serviceLocationCode?.type === 'required' && (
              <p role="alert">Service Location Code is required</p>
            )}
          </Form.Item>
        </form>
      </Drawer>
      <Modal
        title="Retire Site"
        open={openRetireSiteModal}
        onCancel={() => setOpenRetireSiteModal(false)}
        footer={[
          <Button
            key="back"
            size="large"
            onClick={() => setOpenRetireSiteModal(false)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            size="large"
            danger
            loading={loadingDelete}
            onClick={() => deleteSite()}
          >
            Retire Site
          </Button>,
        ]}
      >
        Are you sure you want to Remove this Site? This action will remove the
        selected Site and all associated information from the ledger. If you
        wish to Retire any Sites, select Update Site and set Retired to True.
        This action cannot be undone!
      </Modal>{' '}
    </Row>
  );
};

export default ClientSingleSite;
